import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";

const HelpAndSupport = () => {
  const { t } = useTranslation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "600px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "3rem",
          gap: "1rem",
        }}
      >
        <div
          style={{
            fontSize: "28px",
            fontWeight: "500",
          }}
        >
          Help & Support
        </div>
        <div style={{ fontSize: "10px", color: "#808080" }}>
          Effective November 14th 2024
        </div>
        <Divider sx={{ marginBottom: "1rem" }} />
        <div style={{ fontSize: "12px", lineHeight: "2" }}>
          Welcome to Togather's Help & Support Center. We're here to ensure your
          experience with our platform is seamless and rewarding. Our dedicated
          support team is ready to assist you with any questions, technical
          issues, or guidance you may need to make the most of our services.{" "}
          <br />
          <br />
          Getting help is simple - just send us an email at
          info@createtogather.com. Whether you're encountering technical
          difficulties, need clarification on how to use specific features, or
          want to learn more about our platform's capabilities, our
          knowledgeable team will respond promptly with clear, actionable
          solutions to address your concerns. <br />
          <br />
          We understand that your time is valuable, and we strive to resolve all
          inquiries efficiently. When contacting us, please include relevant
          details about your issue, such as any error messages you've
          encountered or specific features you need help with. Any relevant
          screenshots are also super helpful! This information helps us provide
          you with the most accurate and helpful response possible. Our typical
          response time is within 24 business hours, and we're committed to
          working with you until your matter is fully resolved.
        </div>
      </div>
    </Box>
  );
};

export default HelpAndSupport;
