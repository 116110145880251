import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";
const Feedback = () => {
  const { t } = useTranslation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "600px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "3rem",
          gap: "1rem",
        }}
      >
        <div
          style={{
            fontSize: "28px",
            fontWeight: "500",
          }}
        >
          Feedback
        </div>
        <div style={{ fontSize: "10px", color: "#808080" }}>
          Effective November 14th 2024
        </div>
        <Divider sx={{ marginBottom: "1rem" }} />
        <div style={{ fontSize: "12px", lineHeight: "2" }}>
          We value your feedback and are committed to continuously improving
          your experience with Togather. Whether you have suggestions,
          questions, or concerns about our services, please don't hesitate to
          reach out to us at info@createtogather.com. Our team carefully reviews
          all feedback and uses your insights to enhance our platform for the
          entire community.
        </div>
      </div>
    </Box>
  );
};

export default Feedback;
