import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";

const TermsOfService = () => {
  const { t } = useTranslation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "600px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "3rem",
          gap: "1rem",
          marginBottom: "3rem",
        }}
      >
        <div
          style={{
            fontSize: "28px",
            fontWeight: "500",
          }}
        >
          Terms of Service
        </div>
        <div style={{ fontSize: "10px", color: "#808080" }}>
          Effective November 14th 2024
        </div>
        <Divider />
        <div style={{ fontSize: "12px", lineHeight: 2 }}>
          <p>
            Welcome to Togather! By accessing or using the Togather platform
            (“Service”), you agree to be bound by these Terms of Service
            ("Terms"). These Terms constitute a binding agreement between
            Togather and you. If you do not agree to these Terms, you are not
            authorized to use Togather.
          </p>

          <section>
            <h2>1. Acceptance of Terms</h2>
            <p>
              These Terms govern your access to and use of Togather, a
              marketplace platform owned and operated by Togather, Inc. Togather
              reserves the right to modify, suspend, or discontinue any part of
              the Service at its sole discretion at any time. By continuing to
              use Togather after changes are posted, you accept the revised
              Terms.
            </p>
          </section>

          <section>
            <h2>2. Description of Services</h2>
            <p>
              Togather provides a platform where clients (“Clients”) can post
              projects and professionals (“Providers”) can apply to offer
              services. Togather is solely a facilitator; we are not a party to
              any agreement between Clients and Providers. Togather reserves the
              right to reject, remove, or restrict any project or user at its
              sole discretion.
            </p>
          </section>

          <section>
            <h2>3. User Accounts</h2>
            <ul>
              <li>
                <strong>Eligibility:</strong> You must be at least 18 years old
                to use Togather. Togather may, at its discretion, restrict or
                terminate access for any individual.
              </li>
              <li>
                <strong>Account Security:</strong> You are solely responsible
                for maintaining the confidentiality of your account and any
                activity under your account. Togather assumes no responsibility
                for unauthorized use of your account.
              </li>
              <li>
                <strong>Account Information:</strong> You must provide accurate
                information when registering and keep your information
                up-to-date. Togather is not responsible for any issues arising
                from inaccurate information.
              </li>
            </ul>
          </section>

          <section>
            <h2>4. User Responsibilities and Restrictions</h2>
            <p>
              You agree to comply with all applicable laws and not to engage in
              any activity that disrupts or interferes with the platform.
              Togather reserves the right to investigate and take legal action
              for any unauthorized activity, including, but not limited to:
            </p>
            <ul>
              <li>Violating any laws, regulations, or third-party rights;</li>
              <li>
                Providing false information, impersonating others, or engaging
                in fraud;
              </li>
              <li>
                Interfering with the platform or engaging in harmful activities;
              </li>
              <li>
                Attempting to reverse engineer, copy, or resell any part of
                Togather.
              </li>
            </ul>
          </section>

          <section>
            <h2>5. Payment and Project Terms</h2>
            <ul>
              <li>
                <strong>Project Posting and Payment:</strong> Clients must
                provide accurate project descriptions and agree to pay the
                amount specified. All payments are final, and refunds are solely
                at Togather’s discretion.
              </li>
              <li>
                <strong>Provider Compensation:</strong> Togather will release
                payment to Providers after project completion, minus Togather’s
                platform fee, and subject to Togather's approval. Togather
                reserves the right to withhold payments at its discretion.
              </li>
              <li>
                <strong>Non-Refundable Fees:</strong> All platform fees,
                including service charges, are non-refundable, even if a project
                is canceled or a dispute arises.
              </li>
            </ul>
          </section>

          <section>
            <h2>6. Fees and Payments</h2>
            <ul>
              <li>
                <strong>Platform Fees:</strong> Togather charges a platform fee
                that is automatically deducted before any funds are distributed
                to Providers. Togather reserves the right to change fee
                structures at any time without prior notice.
              </li>
              <li>
                <strong>Third-Party Payment Processors:</strong> Payments are
                processed by third parties. Togather is not liable for any
                issues, fees, or disputes arising from third-party payment
                services.
              </li>
            </ul>
          </section>

          <section>
            <h2>7. Intellectual Property</h2>
            <p>
              All intellectual property rights in Togather, including our
              trademarks, software, content, and design, belong exclusively to
              Togather. Users may not copy, distribute, or use Togather’s
              intellectual property without written permission from Togather.
            </p>
          </section>

          <section>
            <h2>8. User-Generated Content</h2>
            <p>
              All content posted by users is their responsibility. By submitting
              content, users grant Togather a non-exclusive, irrevocable,
              perpetual, worldwide license to use, reproduce, and distribute the
              content in any media. Togather is not liable for any
              user-generated content and reserves the right to remove any
              content at its discretion.
            </p>
          </section>

          <section>
            <h2>9. Termination</h2>
            <p>
              Togather reserves the right to terminate or suspend your account
              and access to the platform at any time, for any reason, including
              breach of these Terms. Users may terminate their accounts by
              contacting Togather, but any outstanding fees or obligations will
              remain in effect.
            </p>
          </section>

          <section>
            <h2>10. Disclaimer of Warranties</h2>
            <p>
              Togather is provided on an “as-is” and “as-available” basis, with
              no warranties of any kind. Togather disclaims all warranties,
              express or implied, including but not limited to the availability,
              accuracy, or reliability of the platform. Users assume all risk
              related to their use of Togather.
            </p>
          </section>

          <section>
            <h2>11. Limitation of Liability</h2>
            <p>
              To the maximum extent permitted by law, Togather shall not be
              liable for any damages, including lost profits, loss of data, or
              incidental, indirect, or punitive damages arising from the use of
              Togather, even if Togather has been advised of the possibility of
              such damages. In no event shall Togather’s total liability exceed
              the fees paid by the user in the past 12 months.
            </p>
          </section>

          <section>
            <h2>12. Indemnification</h2>
            <p>
              You agree to indemnify and hold harmless Togather, its affiliates,
              officers, and employees from any claims, damages, or losses
              resulting from your use of Togather, violation of these Terms, or
              infringement of any rights of another party.
            </p>
          </section>

          <section>
            <h2>13. Governing Law and Arbitration</h2>
            <p>
              These Terms and any disputes arising from the use of Togather
              shall be governed by the laws of [Insert Jurisdiction]. Any
              disputes shall be resolved exclusively through binding arbitration
              in [Insert Location], and users waive any rights to participate in
              class-action litigation.
            </p>
          </section>

          <section>
            <h2>14. Changes to Terms</h2>
            <p>
              Togather reserves the right to modify these Terms at any time.
              Changes will be posted on this page, and continued use of Togather
              constitutes acceptance of the modified Terms.
            </p>
          </section>

          <section>
            <h2>15. Contact Us</h2>
            <p>
              If you have any questions about these Terms, please contact us at:
            </p>
            <p>
              <strong>Email:</strong>{" "}
              <a href="mailto:info@createtogather.com">
                info@createtogather.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </Box>
  );
};

export default TermsOfService;
