import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { supabase } from "./SupabaseClient"; // Import your Supabase client

const PrivateRoute = ({ children, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Use null to indicate the initial loading state

  useEffect(() => {
    const checkUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setIsAuthenticated(!!user);
    };

    checkUser();
  }, []);

  if (isAuthenticated === null) {
    return;
  }

  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
