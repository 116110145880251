import React from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { useState } from "react";

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [viewMode, setViewMode] = useState("menu");
  const isMobile = useMediaQuery("(max-width:600px)");
  const { t } = useTranslation();

  const getActiveTab = () => {
    if (location.pathname.includes("/settings/payments")) return "payments";
    return "general";
  };

  const handleButtonClick = (section) => {
    navigate(`/settings/${section}`);
    if (isMobile) {
      setViewMode("detail");
    }
  };

  const goBackToMenu = () => {
    setViewMode("menu");
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        className="custom-margins settings"
        sx={{
          paddingBottom: "3rem",
          paddingTop: {
            md: "30px",
          },
        }}
      >
        <Grid container spacing={4}>
          {(viewMode === "menu" || !isMobile) && (
            <Grid item xs={12} md={2}>
              <Container
                sx={{
                  padding: "20px",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <button
                  className="settings-btn"
                  style={{
                    backgroundColor:
                      getActiveTab() === "general" ? "#9f7fe3" : "white",
                    color: getActiveTab() === "general" ? "#ffffff" : "#9f7fe3",
                  }}
                  onClick={() => handleButtonClick("general")}
                >
                  {t("general")}
                </button>
                <button
                  className="settings-btn"
                  style={{
                    backgroundColor:
                      getActiveTab() === "payments" ? "#9f7fe3" : "white",
                    color:
                      getActiveTab() === "payments" ? "#ffffff" : "#9f7fe3",
                  }}
                  onClick={() => handleButtonClick("payments")}
                >
                  {t("payments")}
                </button>
              </Container>
            </Grid>
          )}

          {viewMode === "detail" && isMobile && (
            <Grid item xs={12} sx={{ textAlign: "left" }}>
              <IconButton aria-label="back" onClick={goBackToMenu}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
          )}

          {viewMode === "detail" || !isMobile ? (
            <Grid item xs={12} md={10}>
              <Outlet />
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
};

export default Settings;
