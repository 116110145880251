// src/services/ipService.js
import axios from "axios";

const IPINFO_TOKEN = "70c4fb204272ae";

export const getLocation = async () => {
  try {
    const response = await axios.get(`https://ipinfo.io?token=${IPINFO_TOKEN}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching location data:", error);
    return null;
  }
};
