import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { supabase } from "./SupabaseClient";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectComponentsProvider,
  ConnectAccountManagement,
  ConnectAccountOnboarding,
} from "@stripe/react-connect-js";

const PaymentSettings = () => {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [accountLinkCreatePending, setAccountLinkCreatePending] =
    useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState(false);
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);

  // Initialize Stripe Connect instance
  const initializeConnect = async (accountId) => {
    try {
      const connectInstance = await loadConnectAndInitialize({
        publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
        fetchClientSecret: async () => {
          try {
            const response = await fetch(
              "https://54rr0rbk9h.execute-api.us-east-1.amazonaws.com/prod/stripe/create-account-session",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  accountId,
                }),
              }
            );

            const data = await response.json();

            if (!response.ok) {
              throw new Error(data.error || "Failed to fetch client secret");
            }

            return data.clientSecret;
          } catch (error) {
            console.error("Error fetching client secret:", error);
            throw error;
          }
        },
        appearance: {
          overlays: "dialog",
          variables: {
            colorPrimary: "#9F7FE3",
            buttonPrimaryColorText: "#FFFFFF",
            spacingUnit: "10px",
          },
        },
      });
      setStripeConnectInstance(connectInstance);
    } catch (error) {
      console.error("Error initializing Connect:", error);
      setError(true);
    }
  };

  const getUser = async () => {
    try {
      const { data, error } = await supabase
        .from("Users")
        .select("stripe_id")
        .eq("id", currentUser.sub);

      if (error) throw error;

      if (data && data[0]?.stripe_id) {
        setAccountId(data[0].stripe_id);
        await initializeConnect(data[0].stripe_id);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
      setError(true);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const createPaymentAccount = async () => {
    setLoading(true);
    setError(null);
    setAccountCreatePending(true);
    setError(false);

    fetch(
      "https://54rr0rbk9h.execute-api.us-east-1.amazonaws.com/prod/stripe/create-account",
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then(async (json) => {
        setAccountCreatePending(false);

        const { accountId, error } = json;

        if (accountId) {
          setConnectedAccountId(accountId);
          await updateStripeId(accountId);
          setLoading(false);
        }

        if (error) {
          setError(true);
        }
      });
  };

  const handleAccountSetup = async () => {
    setAccountLinkCreatePending(true);
    setError(false);
    fetch(
      "https://54rr0rbk9h.execute-api.us-east-1.amazonaws.com/prod/stripe/create-account-link",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accountId: connectedAccountId,
        }),
      }
    )
      .then((response) => response.json())
      .then((json) => {
        setAccountLinkCreatePending(false);

        const { url, error } = json;
        if (url) {
          window.location.href = url;
        }

        if (error) {
          setError(true);
        }
      });
  };

  const updateStripeId = async (id) => {
    const { error } = await supabase
      .from("Users")
      .update({ stripe_id: id })
      .eq("id", currentUser.sub);

    if (error) {
      console.error("Error updating Stripe ID:", error);
      setError(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "20px",
        height: "auto",
      }}
    >
      {loading && <div>Loading...</div>}
      {error && <div>Something went wrong. Please try again.</div>}

      {!accountCreatePending && !connectedAccountId && !accountId && (
        <button className="stripe-btn" onClick={() => createPaymentAccount()}>
          Set Up Payments
        </button>
      )}

      {connectedAccountId && !accountId && !accountLinkCreatePending && (
        <button className="stripe-btn" onClick={() => handleAccountSetup()}>
          Add information
        </button>
      )}

      {accountId && stripeConnectInstance && (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <Container className="settings-container">
            <div>Payment History</div>
            <ConnectPayments />
          </Container>
          <Container className="settings-container">
            <div>Account Management</div>
            <ConnectAccountManagement />
          </Container>

          <Container className="settings-container">
            <div>Required Information</div>
            <ConnectAccountOnboarding />
          </Container>
        </ConnectComponentsProvider>
      )}
    </Box>
  );
};

export default PaymentSettings;
