import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { supabase } from "./SupabaseClient";
import { Avatar } from "@mui/material";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

function stringAvatar(name) {
  const parts = name.split(" ");
  const firstInitial = parts[0][0];
  const secondInitial = parts.length > 1 ? parts[1][0] : ""; // Check if there is a second part, use it if so, or default to an empty string

  return {
    sx: {
      bgcolor: "#fff",
      color: "#000",
    },
    children: `${firstInitial} ${secondInitial}`.trim(), // Trim to remove any trailing space if there's no second initial
  };
}

const Favorites = () => {
  const navigate = useNavigate();
  const colors = ["#333333", "#F28EAC", "#79B4FA", "#9F7FE3"];

  const [myFavorites, setMyFavorites] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  let lastColorIndex = -1;

  // Function to get the next color index
  const getNextColorIndex = () => {
    let nextIndex;
    do {
      nextIndex = Math.floor(Math.random() * colors.length);
    } while (nextIndex === lastColorIndex);
    lastColorIndex = nextIndex;
    return nextIndex;
  };

  const navigateToProjectPage = (id) => {
    navigate(`/project/${id}`);
  };

  const calculateDuration = (duration, durationType) => {
    if (durationType === "Hours") {
      if (duration === "1") {
        return `${duration} Hour`;
      } else {
        return `${duration} Hours`;
      }
    } else if (durationType === "Days") {
      if (duration === "1") {
        return `${duration} Day`;
      } else {
        return `${duration} Days`;
      }
    } else if (durationType === "Weeks") {
      if (duration === "1") {
        return `${duration} Week`;
      } else {
        return `${duration} Weeks`;
      }
    } else if (durationType === "Months") {
      if (duration === "1") {
        return `${duration} Month`;
      } else {
        return `${duration} Months`;
      }
    }
  };

  const getMyFavorites = async () => {
    setInProgress(true);
    const { data, error } = await supabase
      .from("Favorites")
      .select(
        `
  *,
  Users (first_name, last_name),
  Projects (*)
`
      )
      .eq("user", currentUser.sub);
    if (data) {
      setInProgress(false);
      setMyFavorites(data);
    }
  };

  useEffect(() => {
    getMyFavorites();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        className="custom-margins"
        sx={{
          paddingBottom: "3rem",
          paddingTop: "2rem",
        }}
      >
        {" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: "30px",

            alignItems: {
              xs: "center",
              sm: "flex-start",
            },
          }}
          className="custom-margins"
        >
          <Typography
            sx={{ color: "#222222", textAlign: "left", marginBottom: "30px" }}
            variant="h5"
          >
            {t("favorites")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {myFavorites &&
              myFavorites.length > 0 &&
              myFavorites.map((project) => {
                return (
                  <Card key={project.project_id} className="card-project">
                    {project.Projects.media.length > 0 &&
                      ReactPlayer.canPlay(project.Projects.media[0]) && (
                        <CardMedia
                          onClick={() =>
                            navigateToProjectPage(project.project_id)
                          }
                          className="card-media"
                        >
                          <div
                            style={{ position: "relative", height: "234px" }}
                          >
                            <ReactPlayer
                              url={project.Projects.media[0]}
                              width="300px"
                              height="288px"
                              playing={true}
                              loop={true}
                              volume={1}
                              muted={true}
                              controls={false}
                              className="react-player"
                            />
                            <div
                              className="gradient-overlay"
                              style={{
                                position: "absolute",
                                height: "288px",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background:
                                  "linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%)",
                              }}
                            />

                            <div
                              style={{
                                position: "relative",
                                bottom: "288px",
                              }}
                            >
                              <div className="title">
                                {project.Projects.name}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginLeft: "20px",
                              marginBottom: "10px",
                              zIndex: "1",
                              paddingLeft: "1rem",
                              paddingRight: "1rem",
                            }}
                          >
                            <Avatar
                              className="avatar"
                              {...stringAvatar(
                                `${project.Users.first_name} ${project.Users.last_name}`
                              )}
                            />
                            <div className="owner">
                              {`${project.Users.first_name} ${project.Users.last_name}`}
                            </div>
                          </div>
                        </CardMedia>
                      )}
                    {project.Projects.media.length > 0 &&
                      !ReactPlayer.canPlay(project.Projects.media[0]) && (
                        <CardMedia
                          onClick={() =>
                            navigateToProjectPage(project.project_id)
                          }
                          className="card-media"
                          sx={{
                            background: `linear-gradient(
                          0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%
                        ), url(${project.Projects.media[0]}) lightgray 50% / cover no-repeat`,
                          }}
                          title="photoshoot"
                        >
                          <div>
                            <div className="title">
                              {project.Projects.name}{" "}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginLeft: "20px",
                              marginBottom: "10px",
                              paddingLeft: "1rem",
                              paddingRight: "1rem",
                            }}
                          >
                            <Avatar
                              className="avatar"
                              {...stringAvatar(
                                `${project.Users.first_name} ${project.Users.last_name}`
                              )}
                            />
                            <div className="owner">
                              {`${project.Users.first_name} ${project.Users.last_name}`}
                            </div>
                          </div>
                        </CardMedia>
                      )}

                    {!project.Projects.media.length && (
                      <CardMedia
                        onClick={() =>
                          navigateToProjectPage(project.project_id)
                        }
                        className="card-media"
                        sx={{
                          background: `${colors[getNextColorIndex()]}`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                        title="photoshoot"
                      >
                        <div>
                          <div className="title">{project.Projects.name} </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginLeft: "20px",
                            marginBottom: "10px",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <Avatar
                            className="avatar"
                            {...stringAvatar(
                              `${project.Users.first_name} ${project.Users.last_name}`
                            )}
                          />
                          <div className="owner">{`${project.Users.first_name} ${project.Users.last_name}`}</div>
                        </div>
                      </CardMedia>
                    )}

                    <CardContent
                      sx={{ padding: "12px 12px 12px 12px", height: "45px" }}
                    >
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: project.Projects.description,
                        }}
                      ></div>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        className="project-card-btn"
                        onClick={() =>
                          navigateToProjectPage(project.project_id)
                        }
                      >
                        {t("learnMore")}
                      </Button>
                      {/* <IconButton aria-label="delete">
                        <DeleteIcon />
                      </IconButton> */}
                    </CardActions>
                  </Card>
                );
              })}
            {!inProgress && myFavorites.length === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "3rem",
                }}
              >
                <div style={{ maxWidth: "400px", width: "100%" }}>
                  <DotLottieReact
                    src={
                      "https://noozjeclpgnzvbgdtser.supabase.co/storage/v1/object/public/media/no_results.lottie"
                    }
                    loop
                    autoplay
                  />
                </div>

                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#c7c7c7",
                  }}
                >
                  {t("noFavorites")}
                </Typography>
              </div>
            )}
            {inProgress &&
              Array.from({ length: 6 }).map((_, index) => (
                // Your JSX here. Use `index` if you need the iteration count.
                <Card key={index} className="card-project">
                  <CardMedia
                    sx={{ width: "300px", height: "288px" }}
                    title="photoshoot"
                  >
                    {" "}
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="100%"
                    ></Skeleton>
                  </CardMedia>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "10px 8px 0px 8px",
                      height: "110px",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Skeleton variant="text" width="80%"></Skeleton>
                    <Skeleton variant="text" width="80%"></Skeleton>
                  </Box>
                </Card>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Favorites;
