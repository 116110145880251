import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import "yet-another-react-lightbox/styles.css";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Divider from "@mui/material/Divider";
import AppsIcon from "@mui/icons-material/Apps";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import TodayIcon from "@mui/icons-material/Today";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IosShareIcon from "@mui/icons-material/IosShare";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Map from "./Map";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { v4 as uuidv4 } from "uuid";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ReactPlayer from "react-player";
import { supabase } from "./SupabaseClient";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  const nameParts = name.split(" ");
  const initials =
    nameParts.length > 1
      ? `${nameParts[0][0]}${nameParts[1][0]}`
      : `${nameParts[0][0]}`;

  return {
    sx: {
      bgcolor: stringToColor(name),
      cursor: "pointer",
    },
    children: initials.toUpperCase(),
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProjectPage = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [project, setProject] = useState("");
  const { projectId } = useParams();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openFavSnackBar, setOpenFavSnackBar] = useState(false);
  const [images, setImages] = useState([]);
  const [lightBox, setLightBox] = useState([]);
  const [customNote, setCustomNote] = useState("");
  const [currentRoleId, setCurrentRoleId] = useState("");
  const [currentRoleName, setCurrentRoleName] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const location = useLocation();
  const data = location.state;
  const [appliedRoles, setAppliedRoles] = useState([]);
  const [openAppForm, setOpenAppForm] = useState(false);
  const [showApply, setShowApply] = useState(true);
  const [currentFavId, setCurrentFavId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const { t } = useTranslation();

  const navigateToProfile = (userId) => {
    navigate(`/profile/${userId}`);
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleAppFormOpen = () => {
    setOpenAppForm(true);
  };

  const handleAppFormClose = () => {
    setOpenAppForm(false);
  };

  const transformArrayImageList = (array) => {
    return array.map((item, index) => ({
      img: item,
    }));
  };

  const calculateDuration = (duration, durationType) => {
    if (durationType === "Hours") {
      if (duration === "1") {
        return `${duration} Hour`;
      } else {
        return `${duration} Hours`;
      }
    } else if (durationType === "Days") {
      if (duration === "1") {
        return `${duration} Day`;
      } else {
        return `${duration} Days`;
      }
    } else if (durationType === "Weeks") {
      if (duration === "1") {
        return `${duration} Week`;
      } else {
        return `${duration} Weeks`;
      }
    } else if (durationType === "Months") {
      if (duration === "1") {
        return `${duration} Month`;
      } else {
        return `${duration} Months`;
      }
    }
  };

  const transformArrayLightBox = (array) => {
    return array.map((item, index) => {
      // Check if the item is a playable video
      if (ReactPlayer.canPlay(item)) {
        return {
          type: "custom-slide",
          url: item,
        };
      } else {
        // Handle non-video items
        return {
          src: item,
        };
      }
    });
  };

  const applyToRole = (id, name) => {
    setOpenAppForm(true);
    setCurrentRoleId(id);
    setCurrentRoleName(name);
  };

  const getProject = async () => {
    setIsLoading(true);
    const { data, error } = await supabase
      .from("Projects")
      .select(
        `
          *,
          Users (id,first_name, last_name, profile_picture),
          ProjectRoles (
            *,
            Users (id,first_name, last_name, profile_picture)
          )
        `
      )
      .eq("id", projectId);

    if (data) {
      const project = data[0];
      setImages(transformArrayImageList(project.media));
      setLightBox(transformArrayLightBox(project.media));
      //checkForFavorite(project.id);
      if (currentUser && project.owner === currentUser.sub) {
        setShowApply(false);
      }

      if (currentUser) {
        const { data: applicationsData, error: applicationsError } =
          await supabase
            .from("Applications")
            .select("role") // Fetch the UUIDs of the roles the user has applied to
            .eq("project_id", projectId)
            .eq("user", currentUser.sub);

        if (applicationsData) {
          // Create a Set of role UUIDs that the current user has applied to
          const appliedRoleIds = new Set(
            applicationsData.map((app) => app.role) // Use the correct field "role"
          );

          // Update ProjectRoles with an "applied" field
          project.ProjectRoles = project.ProjectRoles.map((role) => ({
            ...role,
            applied: appliedRoleIds.has(role.id), // Check if the role.id is in the set of appliedRoleIds
          }));

          // Update the state with the list of applied role UUIDs
          setAppliedRoles(applicationsData.map((app) => app.role));
        }

        if (applicationsError) {
          console.error("Error fetching applications data:", applicationsError);
        }
      }

      // After modifications, set the updated project data
      setProject(project);
      setTimeout(() => {
        setIsLoading(false);
      }, 600);
    }

    if (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    getProject();
    //setAppliedRoles(localStorage.getItem("appliedRoles") || []);
    checkForFavorite();
  }, [projectId]);

  const handleShareClick = () => {
    const currentUrl = window.location.href; // Get the current URL
    navigator.clipboard
      .writeText(currentUrl) // Copy the URL to the clipboard
      .then(() => {
        setOpenSnackBar(true);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleFavClick = () => {
    setOpenFavSnackBar(true);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleFavSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFavSnackBar(false);
  };

  const handleImageClick = (index) => {
    setIndex(index);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRoleApp = async () => {
    const { data, error } = await supabase
      .from("Applications")
      .insert({ role: currentRoleId, project_id: projectId, note: customNote })
      .select();

    if (error) {
      console.log(error);
    }

    if (data) {
      setOpenAppForm(false);
      setCustomNote("");
      // Directly update the state with the new application
      setProject((prevProject) => {
        const updatedRoles = prevProject.ProjectRoles.map((role) =>
          role.id === currentRoleId ? { ...role, applied: true } : role
        );

        return { ...prevProject, ProjectRoles: updatedRoles };
      });

      // Optionally update the appliedRoles state
      setAppliedRoles((prevAppliedRoles) => [
        ...prevAppliedRoles,
        currentRoleId,
      ]);
    }
  };

  const handleFavorites = async () => {
    if (isFavorite) {
      await removeFromFavorites();
    } else {
      await addToFavorites();
    }
  };

  const removeFromFavorites = async () => {
    setIsFavorite(false);
    const { error } = await supabase
      .from("Favorites")
      .delete()
      .eq("id", currentFavId);
  };

  const addToFavorites = async () => {
    const { error } = await supabase
      .from("Favorites")
      .insert({ project_id: projectId });

    setIsFavorite(true);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackBarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const favAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleFavSnackBarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const checkForFavorite = async () => {
    const { data, error } = await supabase
      .from("Favorites")
      .select("*")
      .eq("project_id", projectId) // Filter where role is 'admin'
      .eq("user", currentUser.sub); // Filter where status is 'active'

    if (error) {
      console.error("Error fetching admins:", error);
    } else {
      if (data.length > 0) {
        setIsFavorite(true);
        setCurrentFavId(data[0].id);
      } else {
        setIsFavorite(false);
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {project && !isLoading ? (
        <React.Fragment>
          <Carousel
            sx={{ display: { xs: "flex", md: "none" } }}
            responsive={responsive}
            partialVisibile={false}
            swipeable={false}
            containerClass="carousel-container"
          >
            {images &&
              images.length > 0 &&
              images.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleImageClick(index)}
                  className="carousel-item"
                >
                  {ReactPlayer.canPlay(item.img) ? (
                    <ReactPlayer
                      width={380}
                      height={250}
                      url={item.img}
                    ></ReactPlayer>
                  ) : (
                    <img
                      src={`${item.img}?w=248&fit=crop&auto=format`}
                      srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.img}
                      loading="lazy"
                    />
                  )}
                </div>
              ))}
          </Carousel>
          <Box
            className="custom-margins project-view"
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "3rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                marginBottom: "24px",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontWeight: "400",
                    fontSize: "26px",
                  }}
                >
                  {project.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <Box
                  sx={{
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: { xs: "flex-start", md: "space-between" },
                  }}
                >
                  <Button
                    sx={{
                      color: "#222222",
                      backgroundColor: "#ffffff",
                      height: "30px",
                      borderRadius: "12px",
                      ":hover": {
                        bgcolor: "#ecececa8",
                      },
                      textTransform: "none",
                      padding: "16px",
                      display: {
                        xs: "none",
                        md: "flex",
                      },
                    }}
                    variant="text"
                    onClick={handleShareClick}
                    startIcon={<IosShareIcon />}
                  >
                    {t("share")}
                  </Button>

                  <Snackbar
                    open={openSnackBar}
                    autoHideDuration={3000}
                    onClose={handleSnackBarClose}
                    message="Link copied"
                    action={action}
                  />
                  <Button
                    sx={{
                      color: "#222222",
                      backgroundColor: "#ffffff",
                      height: "30px",
                      borderRadius: "12px",
                      ":hover": {
                        bgcolor: "#ecececa8",
                      },
                      textTransform: "none",
                      padding: "16px",
                      display: {
                        xs: "none",
                        md: "flex",
                      },
                    }}
                    variant="text"
                    onClick={handleFavorites}
                    startIcon={
                      isFavorite ? (
                        <FavoriteIcon sx={{ color: "red" }} />
                      ) : (
                        <FavoriteBorderIcon />
                      )
                    }
                  >
                    {t("save")}
                  </Button>

                  <Snackbar
                    open={openFavSnackBar}
                    autoHideDuration={3000}
                    onClose={handleFavSnackBarClose}
                    message="Project saved"
                    action={favAction}
                  />
                </Box>
              </Box>
            </Box>
            <Grid
              sx={{ marginBottom: "24px", display: { xs: "none", md: "flex" } }}
              container
              spacing={1}
            >
              {project.media && project.media.length > 0 && (
                <Grid
                  sx={{
                    width: "636px",
                    height: "344px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                  onClick={handleClickOpen}
                >
                  {ReactPlayer.canPlay(project.media[0]) ? (
                    <ReactPlayer
                      width={636}
                      height={336}
                      url={project.media[0]}
                    ></ReactPlayer>
                  ) : (
                    <img
                      className="child-img"
                      src={project.media[0]}
                      alt="cover"
                      style={{
                        borderTopLeftRadius: "12px",
                        borderBottomLeftRadius: "12px",
                      }}
                    ></img>
                  )}
                </Grid>
              )}

              <Grid item xs={6}>
                <Grid container spacing={1}>
                  {project.media &&
                    project.media.slice(1, 5).map((media, index) => (
                      <Grid
                        key={index}
                        sx={{
                          width: "272px",
                          height: "172px",
                          position: "relative",
                          overflow: "hidden",
                        }}
                        item
                        xs={6}
                        onClick={handleClickOpen}
                      >
                        {ReactPlayer.canPlay(media) ? (
                          <ReactPlayer
                            width={314}
                            height={164}
                            url={media}
                          ></ReactPlayer>
                        ) : (
                          <img
                            className="child-img"
                            src={media}
                            alt={`media-${index + 1}`}
                            style={{
                              ...(index === 1 && {
                                borderTopRightRadius: "12px",
                              }),
                              ...(index === 3 && {
                                borderBottomRightRadius: "12px",
                              }),
                            }}
                          ></img>
                        )}
                      </Grid>
                    ))}
                </Grid>
                {project.media && project.media.length > 5 && (
                  <Button
                    onClick={handleClickOpen}
                    className="img-btn"
                    variant="outlined"
                    startIcon={<AppsIcon />}
                    sx={{ textTransform: "none" }}
                  >
                    Show all photos
                  </Button>
                )}
              </Grid>
            </Grid>
            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  padding: "20px 0px 0px 20px !important",
                  marginBottom: "30px",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <IconButton
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <NavigateBeforeIcon />
                </IconButton>
              </Container>
              <Container sx={{ paddingBottom: "40px" }}>
                <ImageList variant="masonry" cols={3} gap={4}>
                  {images &&
                    images.length > 0 &&
                    images.map((item, index) => (
                      <ImageListItem
                        key={index}
                        onClick={() => handleImageClick(index)}
                        sx={{ cursor: "pointer" }}
                      >
                        {ReactPlayer.canPlay(item.img) ? (
                          <ReactPlayer
                            width={380}
                            height={250}
                            url={item.img}
                          ></ReactPlayer>
                        ) : (
                          <img
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.img}
                            loading="lazy"
                          />
                        )}
                      </ImageListItem>
                    ))}
                </ImageList>
                {lightBox && lightBox.length > 0 && (
                  <Lightbox
                    slides={lightBox}
                    plugins={[Video]}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    render={{
                      slide: ({ slide }) =>
                        slide.type === "custom-slide" ? (
                          <ReactPlayer
                            width={1280}
                            height={650}
                            url={slide.url}
                          ></ReactPlayer>
                        ) : undefined,
                    }}
                    // enable optional lightbox plugins
                  />
                )}
              </Container>
            </Dialog>
            <Grid container spacing={8}>
              <Grid item xs={12} md={8}>
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    justifyContent: "left",
                    padding: "0 !important",
                    gap: { xs: "40px", md: "40px" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "row", md: "row" },
                      gap: { xs: "10px", md: "20px" },
                      width: { xs: "100%", md: "auto" },
                      flexWrap: { xs: "wrap", md: "nowrap" },
                    }}
                  >
                    <Avatar
                      className="team-avatar"
                      onClick={() => navigateToProfile(project.Users.id)}
                      {...stringAvatar(
                        `${
                          project.Users.first_name +
                          " " +
                          project.Users.last_name
                        }`
                      )} // Use project.user.name for the avatar
                      src={project.Users.profile_picture}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "6px",
                      }}
                    >
                      <div>
                        {`${project.Users.first_name} ${project.Users.last_name}`}
                      </div>

                      <div style={{ color: "#6a6a6a", fontSize: "14px" }}>
                        {project.owner_role}
                      </div>
                    </div>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "row", md: "row" },
                      gap: { xs: "10px", md: "20px" },
                      width: { xs: "100%", md: "auto" },
                      flexWrap: { xs: "wrap", md: "nowrap" },
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #DDDDDD",
                        borderRadius: "12px",
                        padding: "8px",
                      }}
                    >
                      <Chip
                        sx={{
                          backgroundColor: "#ffffff",
                          "& .MuiChip-label": {
                            fontSize: { xs: "12px", md: "14px" },
                          },
                          "& .MuiChip-icon": {
                            color: "#000000",
                          },
                        }}
                        icon={<GroupsIcon />}
                        label={
                          project.ProjectRoles &&
                          `${project.ProjectRoles.length} People`
                        }
                      />
                    </div>
                    {project.duration && project.duration_type && (
                      <div
                        style={{
                          border: "1px solid #DDDDDD",
                          borderRadius: "12px",
                          padding: "8px",
                        }}
                      >
                        <Chip
                          sx={{
                            backgroundColor: "#ffffff",
                            color: "#000000",
                            "& .MuiChip-label": {
                              fontSize: { xs: "12px", md: "14px" },
                              color: "#000000",
                            },
                            "& .MuiChip-icon": {
                              color: "#000000",
                            },
                          }}
                          icon={<TimelapseIcon />}
                          label={calculateDuration(
                            project.duration,
                            project.duration_type
                          )}
                        />
                      </div>
                    )}

                    {project.type === "paid" && (
                      <div
                        style={{
                          border: "1px solid #DDDDDD",
                          borderRadius: "12px",
                          padding: "8px",
                        }}
                      >
                        <Chip
                          sx={{
                            backgroundColor: "#ffffff",
                            color: "#000000",
                            "& .MuiChip-label": {
                              fontSize: { xs: "12px", md: "14px" },
                              color: "#000000",
                            },
                            "& .MuiChip-icon": {
                              color: "#000000",
                            },
                          }}
                          icon={<AttachMoneyIcon />}
                          label={t("paid")}
                        />
                      </div>
                    )}
                    {project.type === "collab" && (
                      <div
                        style={{
                          border: "1px solid #DDDDDD",
                          borderRadius: "12px",
                          padding: "8px",
                        }}
                      >
                        <Chip
                          sx={{
                            backgroundColor: "#ffffff",
                            color: "#000000",
                            "& .MuiChip-label": {
                              fontSize: { xs: "12px", md: "14px" },
                              color: "#000000",
                            },
                            "& .MuiChip-icon": {
                              color: "#000000",
                            },
                          }}
                          icon={<WorkspacesIcon />}
                          label={t("collab")}
                        />
                      </div>
                    )}
                    <IconButton
                      onClick={handleShareClick}
                      sx={{
                        display: {
                          xs: "flex",
                          md: "none",
                        },
                      }}
                    >
                      <IosShareIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleFavorites}
                      sx={{
                        display: {
                          xs: "flex",
                          md: "none",
                        },
                      }}
                    >
                      {isFavorite ? (
                        <FavoriteIcon sx={{ color: "red" }} />
                      ) : (
                        <FavoriteBorderIcon />
                      )}
                    </IconButton>
                  </Box>
                </Container>
                <Divider sx={{ marginTop: "30px", marginBottom: "30px" }} />

                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "left",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    {t("aboutTheProject")}
                  </Typography>

                  <Box
                    sx={{
                      textAlign: "left",
                      display: "flex",
                      marginBottom: "20px",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <TodayIcon sx={{ marginRight: "10px", fontSize: "14px" }} />
                    <Typography
                      variant="button"
                      sx={{
                        textAlign: "left",
                        fontWeight: "300",
                        textTransform: "none",
                        fontSize: "12px",
                      }}
                    >
                      {t("projectDate")}: {dayjs(project.date).format("M/D/YY")}
                    </Typography>
                  </Box>

                  <Typography
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: project.description,
                    }}
                  ></Typography>
                  {project && project.coordinates && (
                    <Divider
                      sx={{
                        marginTop: "30px",
                        marginBottom: "30px",
                      }}
                    />
                  )}
                  <Container
                    sx={{
                      padding: "30px !important",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      borderRadius: "10px",
                      height: "100%",
                      marginBottom: "2rem",
                      flexDirection: "column",
                      display: {
                        xs: "flex",
                        md: "none",
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ textAlign: "left", fontWeight: "400" }}
                    >
                      {t("team")}
                    </Typography>
                    {project.ProjectRoles &&
                      project.ProjectRoles.map(
                        (item) =>
                          item.user && ( // Check if item.user is not null
                            <Container
                              key={item.id + item.name}
                              sx={{
                                padding: "0px !important",
                                display: "flex",
                                alignItems: "center",
                                marginTop: "20px",
                              }}
                            >
                              <Avatar
                                className="team-avatar"
                                onClick={() => navigateToProfile(item.Users.id)} // Use item.user.id for navigating to the user's profile
                                {...stringAvatar(
                                  `${
                                    item.Users.first_name +
                                    " " +
                                    item.Users.last_name
                                  }`
                                )} // Use item.user.name for the avatar
                              />
                              <Typography
                                onClick={() => navigateToProfile(item.Users.id)} // Use item.user.id for navigating to the user's profile
                                variant="overline"
                                sx={{
                                  textAlign: "left",
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                              >
                                {`${
                                  item.Users.first_name +
                                  " " +
                                  item.Users.last_name
                                }`}
                              </Typography>
                              <Chip
                                sx={{ marginLeft: "10px" }}
                                label={item.name}
                              />
                            </Container>
                          )
                      )}

                    <Divider sx={{ marginTop: "20px" }} variant="middle" />
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        textAlign: "left",
                        fontWeight: "400",
                        marginTop: "20px",
                      }}
                    >
                      {t("openRoles")}
                    </Typography>
                    {project.ProjectRoles &&
                      project.ProjectRoles.filter(
                        (item) =>
                          item.filled === false || item.filled === undefined
                      ) // Filter items where filled is false
                        .map((item) => (
                          <Container
                            key={item.id + item.name}
                            sx={{
                              padding: "0px !important",
                              display: "flex",
                              alignItems: "center",
                              marginTop: "20px",
                            }}
                          >
                            <Avatar>
                              <PersonIcon />
                            </Avatar>{" "}
                            {showApply && !appliedRoles.includes(item.id) && (
                              <Button
                                sx={{ marginLeft: "10px" }}
                                onClick={() => applyToRole(item.id, item.name)}
                              >
                                Apply
                              </Button>
                            )}
                            <Chip
                              sx={{ marginLeft: "10px" }}
                              label={item.name}
                            />
                          </Container>
                        ))}

                    <Dialog
                      open={openAppForm}
                      PaperProps={{
                        sx: {
                          padding: "30px", // Add padding to the entire dialog
                        },
                      }}
                      onClose={handleAppFormClose}
                    >
                      <DialogTitle>Apply to Role</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Please add a custom note for the project owner. Your
                          profile will be shared once you apply.
                        </DialogContentText>
                        <TextField
                          multiline
                          rows={6}
                          fullWidth
                          sx={{ marginTop: "20px" }}
                          value={customNote}
                          onChange={(e) => setCustomNote(e.target.value)}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleAppFormClose}>Cancel</Button>
                        <Button onClick={handleRoleApp}>Apply</Button>
                      </DialogActions>
                    </Dialog>
                  </Container>

                  {project && project.coordinates && (
                    <React.Fragment>
                      {" "}
                      <Divider
                        sx={{
                          marginTop: "30px",
                          marginBottom: "30px",
                          display: { xs: "flex", md: "none" },
                        }}
                      />
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          textAlign: "left",
                          fontWeight: "500",
                          textTransform: "none",
                          marginBottom: "20px",
                        }}
                      >
                        {t("location")}
                      </Typography>{" "}
                      <Box sx={{ width: "auto", height: "400px" }}>
                        <Map center={project.coordinates} zoom={14} />
                      </Box>
                    </React.Fragment>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                <Container
                  sx={{
                    padding: "30px !important",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderRadius: "10px",
                    height: "fit-content",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ textAlign: "left", fontWeight: "400" }}
                  >
                    {t("team")}
                  </Typography>
                  {project.ProjectRoles &&
                    project.ProjectRoles.map(
                      (item) =>
                        item.user && ( // Check if item.user is not null
                          <Container
                            key={item.id + item.name}
                            sx={{
                              padding: "0px !important",
                              display: "flex",
                              alignItems: "center",
                              marginTop: "20px",
                            }}
                          >
                            <Avatar
                              className="team-avatar"
                              onClick={() => navigateToProfile(item.Users.id)} // Use item.user.id for navigating to the user's profile
                              {...stringAvatar(
                                `${
                                  item.Users.first_name +
                                  " " +
                                  item.Users.last_name
                                }`
                              )} // Use item.user.name for the avatar
                              src={item.Users.profile_picture}
                            />
                            <Typography
                              onClick={() => navigateToProfile(item.Users.id)} // Use item.user.id for navigating to the user's profile
                              sx={{
                                textAlign: "left",
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                            >
                              {`${
                                item.Users.first_name +
                                " " +
                                item.Users.last_name
                              }`}
                            </Typography>
                            <Chip
                              sx={{ marginLeft: "10px" }}
                              label={item.name}
                            />
                          </Container>
                        )
                    )}

                  <Divider sx={{ marginTop: "20px" }} variant="middle" />
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      fontWeight: "400",
                      marginTop: "20px",
                    }}
                  >
                    {t("openRoles")}
                  </Typography>
                  {project.ProjectRoles &&
                    project.ProjectRoles.filter(
                      (item) =>
                        item.filled === false || item.filled === undefined
                    ) // Filter items where filled is false
                      .map((item) => (
                        <Container
                          key={item.id + item.name}
                          sx={{
                            padding: "0px !important",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "20px",
                            gap: "10px",
                          }}
                        >
                          <Avatar>
                            <PersonIcon />
                          </Avatar>{" "}
                          {showApply && !appliedRoles.includes(item.id) && (
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#ffffff",
                                color: "#000000",
                                textTransform: "none",
                                boxShadow: "none",
                                fontSize: "10px",
                                fontWeight: "400",
                                padding: "2px 2px",
                                "&:hover": {
                                  backgroundColor: "#ffffff",
                                  boxShadow: "none",
                                },
                                border: "0.2px solid #000000",
                              }}
                              onClick={() => applyToRole(item.id, item.name)}
                            >
                              Apply
                            </Button>
                          )}
                          {showApply && appliedRoles.includes(item.id) && (
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#ffffff",
                                color: "#000000",
                                textTransform: "none",
                                boxShadow: "none",
                                fontSize: "10px",
                                fontWeight: "400",
                                padding: "2px 2px",
                                "&:hover": {
                                  backgroundColor: "#ffffff",
                                  boxShadow: "none",
                                },
                                border: "0.2px solid #000000",
                              }}
                            >
                              Applied
                            </Button>
                          )}
                          <Chip label={item.name} />
                        </Container>
                      ))}

                  <Dialog
                    open={openAppForm}
                    PaperProps={{
                      sx: {
                        padding: "30px", // Add padding to the entire dialog
                      },
                    }}
                    onClose={handleAppFormClose}
                  >
                    <DialogTitle>Apply to Role</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Please add a custom note for the project owner. Your
                        profile will be shared once you apply.
                      </DialogContentText>
                      <TextField
                        multiline
                        rows={6}
                        fullWidth
                        sx={{ marginTop: "20px" }}
                        value={customNote}
                        onChange={(e) => setCustomNote(e.target.value)}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleAppFormClose}>Cancel</Button>
                      <Button onClick={handleRoleApp}>Apply</Button>
                    </DialogActions>
                  </Dialog>
                </Container>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
      ) : (
        <Stack
          className="custom-margins"
          spacing={4}
          sx={{
            paddingBottom: "2rem",
          }}
        >
          <Skeleton
            variant="rounded"
            height={300}
            sx={{
              display: {
                xs: "flex",
                md: "none",
              },
            }}
          />
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="rounded" width={320} height={40} />
          <Grid
            sx={{
              marginBottom: "40px",
              display: {
                xs: "none",
                md: "flex",
              },
            }}
            container
            spacing={1}
          >
            <Grid
              sx={{
                width: "636px",
                height: "344px",
                position: "relative",
                overflow: "hidden",
                paddingLeft: "0px !important",
              }}
              item
              xs={6}
            >
              <Skeleton variant="rounded" height={336} width={640} />
            </Grid>

            <Grid
              item
              xs={6}
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={164} />
                </Grid>

                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={164} />
                </Grid>

                <Grid
                  sx={{
                    width: "270px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={164} />
                </Grid>

                <Grid
                  sx={{
                    width: "270px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={164} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Skeleton variant="rounded" height={300} />
        </Stack>
      )}
    </Box>
  );
};

export default ProjectPage;
