import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

export const resizeAndCompressImage = (
  file,
  maxWidth,
  maxHeight,
  quality,
  useWebP = false
) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      // Calculate the new dimensions
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      // Resize the image
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      // Compress the image and convert to WebP if required
      const mimeType = useWebP ? "image/webp" : file.type;
      canvas.toBlob(resolve, mimeType, quality);
    };

    img.onerror = reject;
  });
};

export const uploadFile = async (file, bucket = "media") => {
  const { data, error } = await supabase.storage
    .from(bucket)
    .upload(file.name, file, {
      cacheControl: "3600",
      upsert: true,
      onUploadProgress: (progress) => {
        console.log("Upload Progress:", progress.loaded, "/", progress.total);
      },
    });

  if (error) {
    throw error;
  }

  return data.path;
};

export const handleUpload = async ({
  images,
  profile,
  project,
  profileImage,
}) => {
  // Only process images that are new and need to be uploaded
  const newImages = images.filter((image) => image.isNew);

  const uploadPromises = newImages.map(async (image) => {
    if (image.type === "video") {
      return image.content;
    }

    try {
      const response = await fetch(image.content);
      const blob = await response.blob();

      const resizedBlob = await resizeAndCompressImage(
        blob,
        2048, // Width
        2048, // Height
        0.9, // Quality
        true // Convert to WebP
      );

      // Generate a timestamp to ensure unique filenames
      const timestamp = Date.now();
      // Use the index in the filename to maintain order
      const index = images.indexOf(image);
      const filename = `new_${timestamp}_${index}.webp`;
      let name;

      if (profile) {
        name = `profiles/${profile}/${filename}`;
      } else if (project) {
        name = `projects/${project}/${filename}`;
      } else if (profileImage) {
        name = `profileImages/${profileImage}/${filename}`;
      }

      const file = new File([resizedBlob], name, { type: "image/webp" });

      // Upload the file to Supabase Storage
      const path = await uploadFile(file);

      // Get the public URL
      const {
        data: { publicUrl },
      } = supabase.storage.from("media").getPublicUrl(path);

      return publicUrl;
    } catch (error) {
      console.error("Error uploading image:", image.id, error);
      return null;
    }
  });

  if (uploadPromises.length === 0) {
    return []; // Return empty array if no new images to upload
  }

  const media = await Promise.all(uploadPromises);
  return media.filter((url) => url !== null);
};

export const deleteFromStorageAndDB = async (projectId, selectedImages) => {
  try {
    // 1. Delete all files from storage
    const paths = selectedImages.map((image) =>
      image.content.split("/").slice(-3).join("/")
    );

    const { error: storageError } = await supabase.storage
      .from("media")
      .remove(paths);

    if (storageError) {
      console.error("Error deleting from storage:", storageError);
      return { success: false, error: storageError };
    }

    // 2. Get current media array
    const { data: project, error: fetchError } = await supabase
      .from("Projects")
      .select("media")
      .eq("id", projectId)
      .single();

    if (fetchError) {
      console.error("Error fetching project:", fetchError);
      return { success: false, error: fetchError };
    }

    // 3. Filter out all deleted URLs
    const urlsToDelete = selectedImages.map((image) => image.content);
    const updatedMedia = project.media.filter(
      (url) => !urlsToDelete.includes(url)
    );

    // 4. Update the project
    const { error: updateError } = await supabase
      .from("Projects")
      .update({ media: updatedMedia })
      .eq("id", projectId);

    if (updateError) {
      console.error("Error updating project:", updateError);
      return { success: false, error: updateError };
    }

    return { success: true, updatedMedia };
  } catch (error) {
    console.error("Unexpected error:", error);
    return { success: false, error };
  }
};

export const deleteProfileImageFromStorage = async (selectedImage) => {
  try {
    const { error } = await supabase.storage
      .from("media")
      .remove([selectedImage]);

    if (error) {
      throw error;
    }
  } catch (error) {
    console.log(`Error deleting image ${selectedImage}:`, error);
  }
};
