import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Chip,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Skeleton,
  Avatar,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactPlayer from "react-player";
import ProjectRoles from "./ProjectRoles";
import { supabase } from "./SupabaseClient";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { UserContext } from "./UserContext";

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7,
    partialVisibilityGutter: 10,
  },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 2 },
};

function stringAvatar(name) {
  const [firstInitial, secondInitial] = name.split(" ").map((n) => n[0]);
  return {
    sx: { bgcolor: "#fff", color: "#000" },
    children: `${firstInitial} ${secondInitial || ""}`.trim(),
  };
}

const ProjectCard = ({
  project,
  navigateToProjectPage,
  navigateToProposalPage,
  colors,
  getNextColorIndex,
  stringAvatar,
  calculateDuration,
}) => {
  const { t } = useTranslation();

  const renderMedia = () => {
    if (project.media.length > 0) {
      if (ReactPlayer.canPlay(project.media[0])) {
        return (
          <CardMedia
            onClick={() =>
              project.proposal_stage === "complete"
                ? navigateToProjectPage(project.id)
                : navigateToProposalPage(project.id)
            }
            className="card-media"
          >
            <div style={{ position: "relative", height: "234px" }}>
              <ReactPlayer
                url={project.media[0]}
                width="300px"
                height="288px"
                playing
                loop
                volume={1}
                muted
                controls={false}
                className="react-player"
              />
              <div
                className="gradient-overlay"
                style={{
                  position: "absolute",
                  height: "288px",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%)",
                }}
              />
              <div
                style={{
                  position: "relative",
                  bottom: "288px",
                }}
              >
                <div className="title">{project.name}</div>
                {project.ProjectRoles &&
                  project.proposal_stage !== "pending" && (
                    <ProjectRoles roles={project.ProjectRoles} />
                  )}
              </div>
            </div>
            {renderOwnerInfo()}
          </CardMedia>
        );
      } else {
        return (
          <CardMedia
            onClick={() =>
              project.proposal_stage === "complete"
                ? navigateToProjectPage(project.id)
                : navigateToProposalPage(project.id)
            }
            className="card-media"
            sx={{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), url(${project.media[0]}) lightgray 50% / cover no-repeat`,
            }}
            title={t("photoshoot")}
          >
            {renderProjectInfo()}
            {renderOwnerInfo()}
          </CardMedia>
        );
      }
    } else {
      return (
        <CardMedia
          onClick={() => navigateToProjectPage(project.id)}
          className="card-media"
          sx={{
            background: `${colors[getNextColorIndex()]}`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          title={t("photoshoot")}
        >
          {renderProjectInfo()}
          {renderOwnerInfo()}
        </CardMedia>
      );
    }
  };

  const renderProjectInfo = () => (
    <div>
      <div className="title">{project.name}</div>

      {project.ProjectRoles && project.proposal_stage !== "pending" && (
        <ProjectRoles roles={project.ProjectRoles} />
      )}
    </div>
  );

  const renderOwnerInfo = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "10px",
        zIndex: 1,
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <Avatar
        className="avatar"
        {...stringAvatar(
          `${project.Users.first_name} ${project.Users.last_name}`
        )}
        src={project.Users.profile_picture}
      />
      <div className="owner">{`${project.Users.first_name} ${project.Users.last_name}`}</div>
    </div>
  );

  return (
    <Card key={project.id} className="card-project">
      {renderMedia()}
      <CardContent sx={{ padding: "12px 12px 12px 12px", height: "50px" }}>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: project.description }}
        />
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "space-around" }}>
        {project.proposal_stage !== "pending" && (
          <Chip
            className="chip"
            icon={<GroupsIcon sx={{ color: "#929292", width: "19px" }} />}
            label={
              project.ProjectRoles && `${project.ProjectRoles.length} people`
            }
          />
        )}
        {project.duration && project.duration_type && (
          <Chip
            className="chip"
            icon={<TimelapseIcon sx={{ color: "#929292", width: "19px" }} />}
            label={calculateDuration(project.duration, project.duration_type)}
          />
        )}
        {project.proposal_stage !== "pending" ? (
          <Button
            size="small"
            variant="outlined"
            className="project-card-btn"
            onClick={() => navigateToProjectPage(project.id)}
          >
            {t("learnMore")}
          </Button>
        ) : (
          <Button
            size="small"
            variant="outlined"
            className="project-card-btn"
            sx={{
              width: "auto",
            }}
            onClick={() => navigateToProposalPage(project.id)}
          >
            {t("submitProposal")}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("collaborate");
  const colors = ["#333333", "#F28EAC", "#79B4FA", "#9F7FE3"];
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const [activeCategory, setActiveCategory] = useState("paid");

  let lastColorIndex = -1;

  const getNextColorIndex = () => {
    let nextIndex;
    do {
      nextIndex = Math.floor(Math.random() * colors.length);
    } while (nextIndex === lastColorIndex);
    lastColorIndex = nextIndex;
    return nextIndex;
  };

  const navigateToProjectPage = (id) => {
    addViews(id);
    navigate(`/project/${id}`);
  };

  const navigateToProposalPage = (id) => {
    navigate(`/proposal/${id}`);
  };

  const navigateToCreateProjectPage = () => {
    navigate("/createproject");
  };

  const addViews = async (id) => {
    const { error } = await supabase.rpc("increment", { row_id: id });
    if (error) {
      console.error(error);
    }
  };

  const fetchProjects = async (orderBy, isPopular = false) => {
    setLoading(true);
    const { data, error } = await supabase
      .from("Projects")
      .select(
        `
      *,
      Users (first_name, last_name, profile_picture),
      ProjectRoles (*)
    `
      )
      .eq("proposal_stage", "complete")
      .order(orderBy, { ascending: false });

    if (data) {
      setLoading(false);
      setProjects(data);
    }
  };

  const fetchProposals = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from("Projects")
      .select(
        `
      *,
      Users (first_name, last_name, profile_picture),
      ProjectRoles (*)
    `
      )
      .eq("type", "paid")
      .eq("proposal_stage", "pending")
      .order("created_at", { ascending: false });

    if (data) {
      setLoading(false);
      setProjects(data);
    }
  };

  const fetchCollabProjects = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from("Projects")
      .select(
        `
      *,
      Users (first_name, last_name, profile_picture),
      ProjectRoles (*)
    `
      )
      .eq("type", "collab")
      .eq("proposal_stage", "complete")
      .order("created_at", { ascending: false });

    if (data) {
      setLoading(false);
      setProjects(data);
    }
  };

  const fetchPaidProjects = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from("Projects")
      .select(
        `
      *,
      Users (first_name, last_name, profile_picture),
      ProjectRoles (*)
    `
      )
      .eq("type", "paid")
      .eq("proposal_stage", "complete")
      .order("created_at", { ascending: false });

    if (data) {
      setLoading(false);
      setProjects(data);
    }
  };

  const getProjectsByFilter = async (option) => {
    setLoading(true);
    const timer = setTimeout(() => {
      if (option === "proposals") {
        fetchProposals();
      } else if (option === "collab") {
        fetchCollabProjects();
      } else if (option === "paid") {
        fetchPaidProjects();
      }
    }, 1000);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchProjects("created_at");
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const calculateDuration = (duration, durationType) => {
    const durations = {
      Hours: "Hour",
      Days: "Day",
      Weeks: "Week",
      Months: "Month",
    };
    return duration > 1
      ? `${duration} ${durations[durationType]}s`
      : `${duration} ${durations[durationType]}`;
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", paddingBottom: "3rem" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box component="main" className="categories-container">
            <Box
              sx={{
                display: "flex",
                gap: 1,
                width: "auto",
                backgroundColor: "#f2f2f2",
                padding: "8px",
                borderRadius: "20px",
              }}
            >
              <Typography
                onClick={() => {
                  setLoading(true);
                  setProjects([]);
                  setActiveCategory("paid");
                  getProjectsByFilter("paid");
                }}
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    activeCategory === "paid" ? "#ffffff" : "#f2f2f2",
                  padding: "6px 12px 6px 12px",
                  borderRadius: "20px",
                  color: activeCategory === "paid" ? "#000000" : "#646464",
                  fontSize: "12px",
                  fontWeight: activeCategory === "paid" ? "400" : "300",
                  transition: "all 0.5s ease",
                  "&:hover": {
                    backgroundColor:
                      activeCategory === "paid" ? "#ffffff" : "#e8e8e8",
                  },
                }}
              >
                {t("paid")}
              </Typography>
              <Typography
                onClick={() => {
                  setLoading(true);
                  setProjects([]);
                  setActiveCategory("collab");
                  getProjectsByFilter("collab");
                }}
                sx={{
                  cursor: "pointer",
                  padding: "6px 12px 6px 12px",
                  backgroundColor:
                    activeCategory === "collab" ? "#ffffff" : "#f2f2f2",
                  borderRadius: "20px",
                  color: activeCategory === "collab" ? "#000000" : "#646464",
                  fontSize: "12px",
                  fontWeight: activeCategory === "collab" ? "400" : "300",
                  transition: "all 0.5s ease",
                  "&:hover": {
                    backgroundColor:
                      activeCategory === "collab" ? "#ffffff" : "#e8e8e8",
                  },
                }}
              >
                {t("collab")}
              </Typography>
              {user && user.verified_manager && (
                <Typography
                  onClick={() => {
                    setLoading(true);
                    setProjects([]);
                    setActiveCategory("proposals");
                    getProjectsByFilter("proposals");
                  }}
                  sx={{
                    cursor: "pointer",
                    padding: "6px 12px 6px 12px",
                    backgroundColor:
                      activeCategory === "proposals" ? "#ffffff" : "#f2f2f2",
                    borderRadius: "20px",
                    color:
                      activeCategory === "proposals" ? "#000000" : "#646464",
                    fontSize: "12px",
                    fontWeight: activeCategory === "proposals" ? "400" : "300",
                    transition: "all 0.5s ease",
                    "&:hover": {
                      backgroundColor:
                        activeCategory === "proposals" ? "#ffffff" : "#e8e8e8",
                    },
                  }}
                >
                  {t("acceptingProposals")}
                </Typography>
              )}
            </Box>
            {/* <Box sx={{ display: "flex", gap: 3 }}>
              <Typography
                onClick={() => {
                  setLoading(true);
                  setProjects([]);
                  setActiveCategory("paid");
                  getProjectsByFilter("paid");
                }}
                sx={{
                  cursor: "pointer",
                  position: "relative",
                  padding: "6px 8px 6px 8px",
                  fontSize: "14px",
                  fontWeight: activeCategory === "paid" ? "400" : "300",
                  transition: "all 0.3s ease",
                  opacity: activeCategory === "paid" ? 1 : 0.7,
                  transform:
                    activeCategory === "paid" ? "translateY(-2px)" : "none",
                  "&:hover": {
                    opacity: 1,
                    transform: "translateY(-2px)",
                    "&::after": {
                      width: "100%", // Expand to full width on hover
                    },
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: "50%", // Start at the center
                    transform: "translateX(-50%)",
                    height: "2px",
                    width: activeCategory === "paid" ? "100%" : "0", // Match active state
                    backgroundColor: "#9F7FE3",
                    transition: "width 0.8s ease", // Smooth expansion
                  },
                }}
              >
                {t("paid")}
              </Typography>
              <Typography
                onClick={() => {
                  setLoading(true);
                  setProjects([]);
                  setActiveCategory("collab");
                  getProjectsByFilter("collab");
                }}
                sx={{
                  cursor: "pointer",
                  position: "relative",
                  padding: "6px 8px 6px 8px",
                  fontSize: "14px",
                  fontWeight: activeCategory === "collab" ? "400" : "300",
                  transition: "all 0.3s ease",
                  opacity: activeCategory === "collab" ? 1 : 0.7,
                  transform:
                    activeCategory === "collab" ? "translateY(-2px)" : "none",
                  "&:hover": {
                    opacity: 1,
                    transform: "translateY(-2px)",
                    "&::after": {
                      width: "100%", // Expand to full width on hover
                    },
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: "50%", // Start at the center
                    transform: "translateX(-50%)",
                    height: "2px",
                    width: activeCategory === "collab" ? "100%" : "0", // Match active state
                    backgroundColor: "#9F7FE3",
                    transition: "width 0.8s ease", // Smooth expansion
                  },
                }}
              >
                {t("collab")}
              </Typography>
              {user && user.verified_manager && (
                <Typography
                  onClick={() => {
                    setLoading(true);
                    setProjects([]);
                    setActiveCategory("proposals");
                    getProjectsByFilter("proposals");
                  }}
                  sx={{
                    cursor: "pointer",
                    position: "relative",
                    padding: "6px 8px 6px 8px",
                    fontSize: "14px",
                    fontWeight: activeCategory === "proposals" ? "400" : "300",
                    transition: "all 0.3s ease",
                    opacity: activeCategory === "proposals" ? 1 : 0.7,
                    transform:
                      activeCategory === "proposals"
                        ? "translateY(-2px)"
                        : "none",
                    "&:hover": {
                      opacity: 1,
                      transform: "translateY(-2px)",
                      "&::after": {
                        width: "100%", // Expand to full width on hover
                      },
                    },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: "50%", // Start at the center
                      transform: "translateX(-50%)",
                      height: "2px",
                      width: activeCategory === "proposals" ? "100%" : "0", // Match active state
                      backgroundColor: "#9F7FE3",
                      transition: "width 0.8s ease", // Smooth expansion
                    },
                  }}
                >
                  {t("acceptingProposals")}
                </Typography>
              )}
            </Box> */}
            {/* <Carousel
              responsive={responsive}
              partialVisibile={true}
              swipeable={true}
              draggable={true}
              arrows={false}
              containerClass="category-carousel"
              itemClass="category-item-class"
              removeArrowOnDeviceType="mobile"
            >
              {categories.map((category) => (
                <button
                  key={category.name}
                  className="button-flex"
                  onClick={() => getProjectsByCategory(category.name)}
                >
                  {category.name}
                </button>
              ))}
            </Carousel> */}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginBottom: "30px",
              marginTop: "30px",
            }}
            className="custom-margins"
          >
            {!loading && projects.length === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "3rem",
                  animation: "fadeIn 0.8s ease-in-out",
                }}
              >
                <div
                  style={{
                    maxWidth: "400px",
                    width: "100%",
                    animation: "slideDown 1s ease-in-out",
                    animationFillMode: "backwards",
                    animationDelay: "0.1s",
                  }}
                >
                  <DotLottieReact
                    src={
                      "https://noozjeclpgnzvbgdtser.supabase.co/storage/v1/object/public/media/no_results.lottie"
                    }
                    loop
                    autoplay
                  />
                </div>

                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#c7c7c7",
                    animation: "fadeInUp 0.8s ease-in-out",
                    animationFillMode: "backwards",
                    animationDelay: "0.6s",
                    "@keyframes fadeInUp": {
                      "0%": {
                        opacity: 0,
                        transform: "translateY(20px)",
                      },
                      "100%": {
                        opacity: 1,
                        transform: "translateY(0)",
                      },
                    },
                    "@keyframes fadeIn": {
                      "0%": {
                        opacity: 0,
                      },
                      "100%": {
                        opacity: 1,
                      },
                    },
                    "@keyframes slideDown": {
                      "0%": {
                        opacity: 0,
                        transform: "translateY(-20px)",
                      },
                      "100%": {
                        opacity: 1,
                        transform: "translateY(0)",
                      },
                    },
                  }}
                >
                  {t("noProjects")}
                </Typography>
              </div>
            )}
            <Box className="cards-container">
              {loading &&
                Array.from({ length: 4 }).map((_, index) => (
                  <Card key={index} className="card-project">
                    <CardMedia
                      sx={{ width: "300px", height: "288px" }}
                      title="photoshoot"
                    >
                      <Skeleton variant="rounded" width="100%" height="100%" />
                    </CardMedia>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "10px 8px 0px 8px",
                        height: "110px",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Skeleton variant="text" width="80%" />
                      <Skeleton variant="text" width="80%" />
                    </Box>
                  </Card>
                ))}
              {projects.map((project) => (
                <ProjectCard
                  key={project.id}
                  project={project}
                  navigateToProjectPage={navigateToProjectPage}
                  colors={colors}
                  getNextColorIndex={getNextColorIndex}
                  stringAvatar={stringAvatar}
                  calculateDuration={calculateDuration}
                  navigateToProposalPage={navigateToProposalPage}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
