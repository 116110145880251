import { useState, useEffect } from "react";

const CACHE_DURATION = 1000 * 60 * 60; // 1 hour in milliseconds
const CACHE_KEY = "exchangeRate";

const useExchangeRate = () => {
  const [rate, setRate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        // Check cache first
        const cached = localStorage.getItem(CACHE_KEY);
        if (cached) {
          const { value, timestamp } = JSON.parse(cached);
          if (Date.now() - timestamp < CACHE_DURATION) {
            setRate(value);
            setLoading(false);
            return;
          }
        }

        // If no cache or expired, fetch new rate
        const response = await fetch(
          `https://api.exchangerate-api.com/v4/latest/USD`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch exchange rate");
        }

        const data = await response.json();
        const krwRate = data.rates.KRW;

        // Cache the new rate
        localStorage.setItem(
          CACHE_KEY,
          JSON.stringify({
            value: krwRate,
            timestamp: Date.now(),
          })
        );

        setRate(krwRate);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching exchange rate:", err);
        setError(err);
        setLoading(false);

        // Fallback to last cached value if available
        const cached = localStorage.getItem(CACHE_KEY);
        if (cached) {
          const { value } = JSON.parse(cached);
          setRate(value);
        } else {
          // Ultimate fallback to fixed rate
          setRate(1350);
        }
      }
    };

    fetchExchangeRate();
  }, []);

  return { rate, loading, error };
};

export default useExchangeRate;
