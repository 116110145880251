import React from "react";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { v4 as uuidv4 } from "uuid";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
//import ReactQuill from "react-quill";
//import "react-quill/dist/quill.snow.css"; // Import quill styles
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SortableTool from "./Sortable";
import LinearProgress from "@mui/material/LinearProgress";
import { handleUpload } from "./imageUtils";
import IconButton from "@mui/material/IconButton";
import ReactPlayer from "react-player";
import LinkIcon from "@mui/icons-material/Link";
import { supabase } from "./SupabaseClient";
import axios from "axios";
import { useTranslation } from "react-i18next";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import GroupsIcon from "@mui/icons-material/Groups";
import Avatar from "@mui/material/Avatar";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { Card, CardContent, Radio } from "@mui/material";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";

// let Size = ReactQuill.Quill.import("attributors/style/size");
// Size.whitelist = [
//   "8px",
//   "10px",
//   "12px",
//   "14px",
//   "16px",
//   "18px",
//   "20px",
//   "22px",
//   "24px",
//   "26px",
//   "28px",
//   "30px",
//   "32px",
//   "34px",
//   "36px",
//   "38px",
//   "40px",
// ];
// ReactQuill.Quill.register(Size, true);

// const modules = {
//   toolbar: [
//     // ... (other toolbar options)
//     [{ size: Size.whitelist }],
//     [{ font: [] }], // Dropdown to select font
//     [{ size: ["small", "medium", "large", "huge"] }], // Dropdown to select font size
//     [{ align: [] }], // Dropdown with text align options
//     ["bold", "italic", "underline", "strike"], // Toggles for bold, italic, underline, and strikethrough
//     [{ color: [] }, { background: [] }], // Dropdowns to select text color and background color
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//     ], // Lists and indentation
//     [{ direction: "rtl" }], // Text direction
//   ],
// };

function stringAvatar(name) {
  const [firstInitial, secondInitial] = name.split(" ").map((n) => n[0]);
  return {
    sx: { bgcolor: "#fff", color: "#000" },
    children: `${firstInitial} ${secondInitial || ""}`.trim(),
  };
}

const CreateProject = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [roleOptions, setRoleOptions] = useState([]);
  const [openDialog, toggleOpen] = useState(false);
  const [openRoleDialog, toggleRoleOpen] = useState(false);
  const [projectDate, setProjectDate] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [verifiedManagers, setVerifiedManagers] = useState([]);
  const [dialogValue, setDialogValue] = useState({
    id: "",
    name: "",
  });
  const [userDialogValue, setUserDialogValue] = useState({
    id: "",
    name: "",
  });
  const [userRole, setUserRole] = useState(null);
  const [durationQuantity, setDurationQuantity] = useState("");
  const [duration, setDuration] = useState("Days");
  const [rolesArray, setRolesArray] = useState([]);
  const [currentRole, setCurrentRole] = useState(null);
  const [description, setDescription] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectLocation, setProjectLocation] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openDuration, setOpenDuration] = useState(false);
  const [images, setImages] = useState([]);
  const [roleBudget, setRoleBudget] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [projectCategory, setProjectCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([
    "Fashion",
    "Music",
    "Film",
    "Graphic Art",
    "Photography",
    "Editing",
    "Illustration",
  ]);
  const [disableStepTwo, setDisableStepTwo] = useState(true);
  const [disableStepThree, setDisableStepThree] = useState(true);
  const { t } = useTranslation();
  const [links, setLinks] = useState([]);
  const [currentLink, setCurrentLink] = useState("");
  const inputRef = useRef(null);
  const [projectOwner, setProjectOwner] = useState("");
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("free");

  const handlePaymentOptionChange = (event) => {
    setSelectedPaymentOption(event.target.value);
  };
  const [selectedOption, setSelectedOption] = useState("self");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const checkStepTwo = () => {
    if (projectName && description && projectCategory) {
      setDisableStepTwo(false);
    } else {
      setDisableStepTwo(true);
    }
  };

  useEffect(() => {
    checkStepTwo();
  }, [projectName, description, projectCategory]);

  const checkStepThree = () => {
    if (
      (projectDate || openDate) &&
      ((duration && durationQuantity) || openDuration) &&
      (projectLocation || openLocation)
    ) {
      setDisableStepThree(false);
    } else {
      setDisableStepThree(true);
    }
  };

  useEffect(() => {
    checkStepThree();
  }, [
    projectDate,
    projectLocation,
    durationQuantity,
    duration,
    openDate,
    openLocation,
    openDuration,
  ]);

  const handleImages = (data) => {
    setImages(data);
  };

  const refreshRoles = async () => {
    const { data, error } = await supabase.from("Roles").select();

    if (data) {
      setRoleOptions(data);
    }
  };

  // const getVerifiedManagers = async () => {
  //   const { data, error } = await supabase
  //     .from("Users")
  //     .select("city, profile_picture, first_name, last_name, id")
  //     .eq("verified_manager", true);

  //   if (data.length) {
  //     setVerifiedManagers(data);
  //   }
  // };

  useEffect(() => {
    if (currentUser) {
      const fullName = `${currentUser.first_name} ${currentUser.last_name}`;
      setProjectOwner(fullName);
    }
    async function init() {
      refreshRoles();
    }
    init();
  }, []);

  const handleDialogClose = () => {
    setDialogValue({
      id: uuidv4(),
      name: "",
    });
    toggleOpen(false);
  };

  const handleRoleDialogClose = () => {
    setUserDialogValue({
      id: uuidv4(),
      name: "",
    });
    toggleRoleOpen(false);
  };

  const handleFinalBack = () => {
    if (selectedPaymentOption === "free") {
      setStep(4);
    } else if (selectedPaymentOption === "paid") {
      if (selectedOption === "managed") {
        setStep(3);
      } else {
        setStep(4);
      }
    }
  };

  const handleNewLink = () => {
    setLinks((links) => [...links, currentLink]);
  };

  const handleNewRole = () => {
    setRolesArray((rolesArray) => [
      ...rolesArray,
      {
        id: uuidv4(),
        name: currentRole.name,
        budget: roleBudget,
        filled: false,
      },
    ]);
    setCurrentRole(null);
    setRoleBudget(0);
  };

  const handleRoleSubmit = (event) => {
    event.preventDefault();
    setRoleOptions((roleOptions) => [
      ...roleOptions,
      {
        id: uuidv4(),
        name: dialogValue.name,
      },
    ]);
    setCurrentRole(dialogValue);
    handleDialogClose();
  };

  const handleUserRoleSubmit = (event) => {
    event.preventDefault();
    setRoleOptions((roleOptions) => [
      ...roleOptions,
      {
        id: uuidv4(),
        name: userDialogValue.name,
      },
    ]);
    setUserRole(userDialogValue);
    handleRoleDialogClose();
  };

  const handleDelete = (id) => {
    setRolesArray(rolesArray.filter((role) => role.id !== id));
  };

  const handleLinkDelete = (link) => {
    setLinks(links.filter((item) => item !== link));
  };

  const handleCoordinates = async () => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      projectLocation
    )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

    try {
      const response = await axios.get(url);
      const data = response.data;
      if (data.status !== "OK" || !data.results || data.results.length === 0) {
        throw new Error("Geocoding failed");
      }
      setCoordinates(data.results[0].geometry.location);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewProject = async () => {
    setShowProgress(true);
    try {
      const projectId = uuidv4();
      const mediaUrls = await handleUpload({
        images,
        project: projectId,
      }).catch(console.error);

      const projectData = {
        name: projectName,
        description: description,
        date: projectDate ? projectDate.$d : null,
        open_date: openDate,
        location: projectLocation ? projectLocation : null,
        coordinates: coordinates ? coordinates : null,
        open_location: openLocation,
        duration: durationQuantity ? durationQuantity : null,
        duration_type: duration ? duration : null,
        open_duration: openDuration,
        owner_role: userRole ? userRole : null,
        owner: currentUser.sub,
        links: links && links.length ? links : null,
        id: projectId,
        management: selectedOption,
        media: mediaUrls,
        category: projectCategory,
        proposal_stage:
          selectedOption === "managed" && selectedPaymentOption === "paid"
            ? "pending"
            : "complete",
        type: selectedPaymentOption === "paid" ? "paid" : "collab",
      };

      // Insert project data
      const { error: projectError } = await supabase
        .from("Projects")
        .insert(projectData);

      if (projectError) {
        console.error("Error inserting project:", projectError);
      } else {
        if (rolesArray.length) {
          // Prepare roles data for batch insertion
          const rolesData = rolesArray.map((role) => ({
            project_id: projectId,
            name: role.name,
            filled: false,
            ...(role.budget !== undefined &&
              role.budget !== null && { budget: role.budget }),
          }));

          // Adding an extra item for project owner
          rolesData.push({
            project_id: projectId,
            name: userRole.name,
            user: currentUser.sub,
            filled: true,
          });

          // Add ProjectManager role if project is managed and paid
          if (
            selectedOption === "managed" &&
            selectedPaymentOption === "paid"
          ) {
            rolesData.push({
              project_id: projectId,
              name: "Project Manager",
              filled: false,
            });
          }

          // Create new call sheet
          const callSheetId = uuidv4();
          const callSheetData = {
            id: callSheetId,
            project_id: projectId,
          };

          // Insert call sheet data
          const { error: callSheetError } = await supabase
            .from("CallSheets")
            .insert(callSheetData);

          // Batch insert roles data
          const { error: rolesError } = await supabase
            .from("ProjectRoles")
            .insert(rolesData);

          if (rolesError) {
            console.error("Error inserting project roles:", rolesError);
          } else {
            navigate(`/project/${projectId}`);
          }
        }
      }
      if (selectedOption === "managed" && selectedPaymentOption === "paid") {
        setStep("managers");
        window.scrollTo(0, 0);
      }
      setShowProgress(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (step !== 3) return;

    // Initialize Google Maps Places Autocomplete
    const initializeAutocomplete = () => {
      if (!inputRef.current) {
        return;
      }
      try {
        const autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current
        );
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (!place.formatted_address && !place.name) {
            console.error(
              "No formatted_address or name found in the selected place."
            );
            return;
          }
          inputRef.current.value = place.formatted_address || place.name;
          setProjectLocation(place.formatted_address || place.name);
        });
      } catch (error) {
        console.error("Error initializing autocomplete:", error);
      }
    };

    if (window.google && window.google.maps && window.google.maps.places) {
      initializeAutocomplete();
    } else {
      window.initializeGooglePlacesAutocomplete = initializeAutocomplete;
    }
  }, [step]);

  const handleChange = (event) => {
    setDuration(event.target.value);
  };

  const handleAddItem = (event) => {
    event.stopPropagation();
    toggleOpen(true);
  };

  const handleUserRoleItem = (event) => {
    event.stopPropagation();
    toggleRoleOpen(true);
  };

  const truncateLabel = (label) => {
    return label.length > 10 ? label.substring(0, 10) + "..." : label;
  };

  const handleGetStarted = () => {
    if (selectedPaymentOption === "free") {
      setStep(2);
    }

    if (selectedPaymentOption === "paid") {
      setStep(1);
    }
  };

  const handleBackStep = () => {
    if (selectedPaymentOption === "free") {
      setStep(0);
    } else if (selectedPaymentOption === "paid") {
      setStep(1);
    }
  };

  const openLinkInNewTab = (url) => {
    // Check if the URL is non-empty and valid
    if (url) {
      // Open the URL in a new tab
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  const handleNextStep = () => {
    if (selectedPaymentOption === "paid" && selectedOption === "managed") {
      setStep(5);
    } else {
      setStep(4);
    }
  };

  const CustomOption = styled("li")(({ theme }) => ({
    [`&.${autocompleteClasses.option}`]: {
      justifyContent: "left",
      backgroundColor: "#f9f9f9",
    },
  }));

  const handleStepNavigation = () => {
    setStep(3);
  };

  return (
    <Box
      className="divider-shadow"
      sx={{
        display: "flex",
        justifyContent: "center",
        bgcolor: "#ffffff",
        paddingBottom: "10px",
        paddingTop: "20px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "1000px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "left",
          }}
          className="create-project"
        >
          {step > 1 && selectedOption !== "managed" && (
            <div className="stepper-container">
              <Avatar
                sx={{
                  backgroundColor: step === 2 && "#9f7fe3",
                  width: 30,
                  height: 30,
                }}
              >
                <DescriptionIcon sx={{ width: 15, height: 15 }} />
              </Avatar>
              <div className="divider"></div>
              <Avatar
                sx={{
                  backgroundColor: step === 3 && "#9f7fe3",
                  width: 30,
                  height: 30,
                }}
              >
                <LocationOnIcon sx={{ width: 15, height: 15 }} />
              </Avatar>
              <div className="divider"></div>
              <Avatar
                sx={{
                  backgroundColor: step === 4 && "#9f7fe3",
                  width: 30,
                  height: 30,
                }}
              >
                <GroupsIcon sx={{ width: 15, height: 15 }} />
              </Avatar>
              <div className="divider"></div>
              <Avatar
                sx={{
                  backgroundColor: step === 5 && "#9f7fe3",
                  width: 30,
                  height: 30,
                }}
              >
                <ImageIcon sx={{ width: 15, height: 15 }} />
              </Avatar>
            </div>
          )}

          {step > 1 &&
            selectedOption === "managed" &&
            selectedPaymentOption === "paid" && (
              <div className="stepper-container">
                <Avatar
                  sx={{
                    backgroundColor: step === 2 && "#9f7fe3",
                    width: 30,
                    height: 30,
                  }}
                >
                  <DescriptionIcon sx={{ width: 15, height: 15 }} />
                </Avatar>
                <div className="divider"></div>
                <Avatar
                  sx={{
                    backgroundColor: step === 3 && "#9f7fe3",
                    width: 30,
                    height: 30,
                  }}
                >
                  <LocationOnIcon sx={{ width: 15, height: 15 }} />
                </Avatar>
                <div className="divider"></div>
                <Avatar
                  sx={{
                    backgroundColor: step === 5 && "#9f7fe3",
                    width: 30,
                    height: 30,
                  }}
                >
                  <ImageIcon sx={{ width: 15, height: 15 }} />
                </Avatar>
              </div>
            )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "40px",
            }}
          >
            {step === 0 && (
              <Box
                width="100%"
                maxWidth="600px"
                p={{ xs: 0, sm: 0, md: 6 }}
                bgcolor="white"
                textAlign="center"
                sx={{
                  animation: "fadeIn 0.8s ease-in-out",
                  "@keyframes fadeIn": {
                    "0%": {
                      opacity: 0,
                      transform: "translateY(20px)",
                    },
                    "100%": {
                      opacity: 1,
                      transform: "translateY(0)",
                    },
                  },
                }}
              >
                <Typography
                  fontWeight="bold"
                  mb={8}
                  sx={{
                    fontSize: { xs: "20px", sm: "20px", md: "24px" },
                    animation: "slideDown 0.8s ease-in-out",
                    "@keyframes slideDown": {
                      "0%": {
                        opacity: 0,
                        transform: "translateY(-20px)",
                      },
                      "100%": {
                        opacity: 1,
                        transform: "translateY(0)",
                      },
                    },
                  }}
                >
                  What type of project will this be?
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-around"
                  mb={12}
                  sx={{
                    animation: "slideIn 0.8s ease-in-out",
                    "@keyframes slideIn": {
                      "0%": {
                        opacity: 0,
                        transform: "translateX(-20px)",
                      },
                      "100%": {
                        opacity: 1,
                        transform: "translateX(0)",
                      },
                    },
                  }}
                >
                  <Card
                    variant="outlined"
                    sx={{
                      width: "45%",
                      borderColor:
                        selectedPaymentOption === "free"
                          ? "#9f7fe3"
                          : "grey.300",
                      borderWidth: 2,
                      borderRadius: "14px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.02)",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          marginBottom: "2rem",
                          fontWeight: "600",
                        }}
                      >
                        Free Collaboration
                      </Typography>
                      <div style={{ fontSize: "14px" }}>
                        People are not paid for their contributions
                      </div>
                      <Radio
                        checked={selectedPaymentOption === "free"}
                        onChange={handlePaymentOptionChange}
                        value="free"
                        name="joinOption"
                        sx={{
                          color: "#9f7fe3",
                          "&.Mui-checked": {
                            color: "#9f7fe3",
                          },
                        }}
                      />
                    </CardContent>
                  </Card>

                  <Card
                    variant="outlined"
                    sx={{
                      width: "45%",
                      borderColor:
                        selectedPaymentOption === "paid"
                          ? "#9f7fe3"
                          : "grey.300",
                      borderWidth: 2,
                      cursor: "pointer",
                      borderRadius: "14px",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.02)",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          marginBottom: "2rem",
                          fontWeight: "600",
                        }}
                      >
                        Paid
                      </Typography>
                      <div style={{ fontSize: "14px" }}>
                        You will provide upfront payment for those involved in
                        your project
                      </div>
                      <Radio
                        checked={selectedPaymentOption === "paid"}
                        onChange={handlePaymentOptionChange}
                        value="paid"
                        name="joinOption"
                        sx={{
                          color: "#9f7fe3",
                          "&.Mui-checked": {
                            color: "#9f7fe3",
                          },
                        }}
                      />
                    </CardContent>
                  </Card>
                </Box>

                <button
                  onClick={handleGetStarted}
                  className="togather-btn"
                  style={{
                    animation: "fadeInUp 0.8s ease-in-out",
                    animationDelay: "0.3s",
                    animationFillMode: "backwards",
                  }}
                >
                  Get Started
                </button>
              </Box>
            )}
            {step === 1 && (
              <Box
                width="100%"
                maxWidth="600px"
                p={6}
                bgcolor="white"
                textAlign="center"
                sx={{
                  animation: "fadeIn 0.8s ease-in-out",
                  "@keyframes fadeIn": {
                    "0%": {
                      opacity: 0,
                      transform: "translateY(20px)",
                    },
                    "100%": {
                      opacity: 1,
                      transform: "translateY(0)",
                    },
                  },
                }}
              >
                <Typography
                  fontWeight="bold"
                  mb={8}
                  sx={{
                    fontSize: "24px",
                    animation: "slideDown 0.8s ease-in-out",
                    "@keyframes slideDown": {
                      "0%": {
                        opacity: 0,
                        transform: "translateY(-20px)",
                      },
                      "100%": {
                        opacity: 1,
                        transform: "translateY(0)",
                      },
                    },
                  }}
                >
                  How would you like to start your project?
                </Typography>

                <Box
                  display="flex"
                  justifyContent="space-around"
                  mb={12}
                  sx={{
                    animation: "slideIn 0.8s ease-in-out",
                    "@keyframes slideIn": {
                      "0%": {
                        opacity: 0,
                        transform: "translateX(-20px)",
                      },
                      "100%": {
                        opacity: 1,
                        transform: "translateX(0)",
                      },
                    },
                  }}
                >
                  <Card
                    variant="outlined"
                    sx={{
                      width: "45%",
                      borderColor:
                        selectedOption === "self" ? "#9f7fe3" : "grey.300",
                      borderWidth: 2,
                      borderRadius: "14px",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.02)",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography sx={{ fontSize: "16px" }}>
                        Create and manage the project myself
                      </Typography>
                      <Radio
                        checked={selectedOption === "self"}
                        onChange={handleOptionChange}
                        value="self"
                        name="joinOption"
                        sx={{
                          color: "#9f7fe3",
                          "&.Mui-checked": {
                            color: "#9f7fe3",
                          },
                        }}
                      />
                    </CardContent>
                  </Card>

                  <Card
                    variant="outlined"
                    sx={{
                      width: "45%",
                      borderColor:
                        selectedOption === "managed" ? "#9f7fe3" : "grey.300",
                      borderWidth: 2,
                      borderRadius: "14px",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.02)",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography sx={{ fontSize: "16px" }}>
                        Hire a verified project manager to create and manage my
                        project
                      </Typography>
                      <Radio
                        checked={selectedOption === "managed"}
                        onChange={handleOptionChange}
                        value="managed"
                        name="joinOption"
                        sx={{
                          color: "#9f7fe3",
                          "&.Mui-checked": {
                            color: "#9f7fe3",
                          },
                        }}
                      />
                    </CardContent>
                  </Card>
                </Box>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    onClick={() => setStep(0)}
                    className="back-btn"
                    style={{
                      animation: "fadeInUp 0.8s ease-in-out",
                      animationDelay: "0.3s",
                      animationFillMode: "backwards",
                    }}
                  >
                    {t("back")}
                  </button>
                  <button
                    onClick={() => setStep(2)}
                    className="togather-btn"
                    style={{
                      animation: "fadeInUp 0.8s ease-in-out",
                      animationDelay: "0.3s",
                      animationFillMode: "backwards",
                      maxWidth: "100px",
                      minWidth: "auto",
                    }}
                  >
                    {t("continue")}
                  </button>
                </div>
              </Box>
            )}
            {step === 2 && (
              <Grid
                container
                spacing={4}
                sx={{
                  animation: "fadeIn 0.8s ease-in-out",
                  "@keyframes fadeIn": {
                    "0%": {
                      opacity: 0,
                      transform: "translateY(20px)",
                    },
                    "100%": {
                      opacity: 1,
                      transform: "translateY(0)",
                    },
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    animation: "fadeInLeft 0.8s ease-in-out",
                    "@keyframes fadeInLeft": {
                      "0%": {
                        opacity: 0,
                        transform: "translateX(-20px)",
                      },
                      "100%": {
                        opacity: 1,
                        transform: "translateX(0)",
                      },
                    },
                  }}
                >
                  {" "}
                  <div className="section">{t("project")}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    animation: "fadeInRight 0.8s ease-in-out",
                    "@keyframes fadeInRight": {
                      "0%": {
                        opacity: 0,
                        transform: "translateX(20px)",
                      },
                      "100%": {
                        opacity: 1,
                        transform: "translateX(0)",
                      },
                    },
                  }}
                >
                  <div
                    className="title"
                    sx={{
                      animation: "fadeInDown 0.8s ease-in-out",
                      "@keyframes fadeInDown": {
                        "0%": {
                          opacity: 0,
                          transform: "translateY(-20px)",
                        },
                        "100%": {
                          opacity: 1,
                          transform: "translateY(0)",
                        },
                      },
                    }}
                  >
                    {t("projectTitle")}
                  </div>
                  <TextField
                    sx={{
                      width: "100%",
                      animation: "fadeIn 0.8s ease-in-out 0.2s",
                      animationFillMode: "backwards",
                    }}
                    variant="outlined"
                    value={projectName || ""}
                    onChange={(e) => setProjectName(e.target.value)}
                  />
                  <div
                    className="title"
                    sx={{
                      animation: "fadeInDown 0.8s ease-in-out 0.3s",
                      animationFillMode: "backwards",
                    }}
                  >
                    {t("projectCategory")}
                  </div>

                  <Autocomplete
                    freeSolo
                    value={projectCategory}
                    onChange={(event, newValue) => {
                      setProjectCategory(newValue);
                    }}
                    options={categoryOptions.map((option) => option)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                      animation: "fadeIn 0.8s ease-in-out 0.4s",
                      animationFillMode: "backwards",
                    }}
                  />

                  <div
                    className="title"
                    sx={{
                      animation: "fadeInDown 0.8s ease-in-out 0.5s",
                      animationFillMode: "backwards",
                    }}
                  >
                    {t("description")}
                  </div>
                  <TextField
                    sx={{
                      width: "100%",
                      animation: "fadeIn 0.8s ease-in-out 0.6s",
                      animationFillMode: "backwards",
                    }}
                    variant="outlined"
                    multiline
                    rows={6}
                    value={description || ""}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    gap: "20px",
                    animation: "fadeInUp 0.8s ease-in-out 0.7s",
                    animationFillMode: "backwards",
                  }}
                >
                  <Button
                    onClick={handleStepNavigation}
                    disabled={disableStepTwo}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("next")}
                  </Button>
                  <Button
                    onClick={handleBackStep}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("back")}
                  </Button>
                </Grid>
              </Grid>
            )}
            {step === "managers" && (
              <Grid
                container
                spacing={4}
                sx={{
                  animation: "fadeIn 0.8s ease-in-out",
                  "@keyframes fadeIn": {
                    "0%": {
                      opacity: 0,
                      transform: "translateY(20px)",
                    },
                    "100%": {
                      opacity: 1,
                      transform: "translateY(0)",
                    },
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    animation: "fadeInDown 0.8s ease-in-out",
                  }}
                >
                  {" "}
                  <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    sx={{ marginBottom: "2rem" }}
                  >
                    {t("thanksForProposal")}
                  </Alert>
                  <div style={{ fontSize: "14px", fontWeight: "300" }}>
                    {t("verifiedManagersDescription")}
                  </div>
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                    animation: "fadeInUp 0.8s ease-in-out 0.2s",
                    animationFillMode: "backwards",
                  }}
                >
                  {verifiedManagers.map((manager, index) => (
                    <div
                      key={manager.id}
                      onClick={() =>
                        window.open(`/profile/${manager.id}`, "_blank")
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "160px",
                        padding: "20px",
                        gap: "12px",
                        backgroundColor: "#fff",
                        borderRadius: "12px",
                        boxShadow: "0 4px 6px rgba(0,0,0,0.06)",
                        margin: "10px 0",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                        border: "1px solid rgba(0,0,0,0.08)",
                        animation: `fadeIn 0.8s ease-in-out ${
                          0.3 + index * 0.1
                        }s`,
                        animationFillMode: "backwards",
                        "&:hover": {
                          transform: "translateY(-4px)",
                          boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                          borderColor: "rgba(0,0,0,0.12)",
                        },
                      }}
                    >
                      <Avatar
                        className="avatar"
                        {...stringAvatar(
                          `${manager.first_name} ${manager.last_name}`
                        )}
                        src={manager.profile_picture}
                        sx={{ width: "100px", height: "100px" }}
                      />
                      <div
                        style={{
                          fontSize: "16px",
                          color: "#333",
                          fontWeight: "300",
                        }}
                      >{`${manager.first_name} ${manager.last_name}`}</div>
                      <div
                        style={{
                          backgroundColor: "rgb(227 214 255)",
                          color: "#9f7fe3",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          fontSize: "10px",
                          fontWeight: "500",
                        }}
                      >
                        Verified Manager
                      </div>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#ffffff",
                          color: "#000000",
                          textTransform: "none",
                          fontSize: "10px",
                          fontWeight: "400",
                          padding: "2px 2px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                          },
                          border: "0.5px solid #000000",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        Invite
                      </Button>
                    </div>
                  ))}
                </Grid> */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                    animation: "fadeInUp 0.8s ease-in-out 0.8s",
                    animationFillMode: "backwards",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      color: "#ffffff",
                      borderColor: "#9f7fe3",
                      textTransform: "none",
                      fontSize: "12px",
                      "&:hover": {
                        borderColor: "#9f7fe3",
                        backgroundColor: "#9f7fe3",
                        color: "#ffffff",
                      },
                    }}
                    onClick={() => navigate("/")}
                  >
                    {t("exit")}
                  </Button>
                </Grid>
              </Grid>
            )}
            {step === 3 && (
              <Grid
                container
                spacing={4}
                sx={{
                  animation: "fadeIn 0.8s ease-in-out",
                  "@keyframes fadeIn": {
                    "0%": {
                      opacity: 0,
                      transform: "translateY(20px)",
                    },
                    "100%": {
                      opacity: 1,
                      transform: "translateY(0)",
                    },
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    animation: "fadeInLeft 0.8s ease-in-out",
                  }}
                >
                  {" "}
                  <div className="section">{t("when")}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    animation: "fadeInRight 0.8s ease-in-out",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      animation: "fadeIn 0.8s ease-in-out 0.2s",
                      animationFillMode: "backwards",
                    }}
                  >
                    <div className="title">{t("date")}</div>
                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        sx={{ marginRight: "5px" }}
                        control={
                          <Checkbox
                            checked={openDate}
                            onChange={(event) =>
                              setOpenDate(event.target.checked)
                            }
                            size="small"
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 16 },
                              color: "#9f7fe3",
                              "&.Mui-checked": {
                                color: "#9f7fe3",
                              },
                            }}
                          />
                        }
                        label={t("open")}
                        componentsProps={{
                          typography: {
                            sx: {
                              fontWeight: "300",
                              fontSize: "12px",
                            },
                          },
                        }}
                      />
                      <Tooltip title={t("openTooltip")} placement="right">
                        <InfoIcon sx={{ color: "#9f7fe3", fontSize: "16px" }} />
                      </Tooltip>
                    </FormGroup>
                  </Box>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={projectDate}
                      onChange={(newValue) => {
                        setProjectDate(newValue);
                      }}
                      sx={{
                        paddingRight: "20px",
                        width: "100%",
                        animation: "fadeIn 0.8s ease-in-out 0.3s",
                        animationFillMode: "backwards",
                      }}
                    />
                  </LocalizationProvider>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      animation: "fadeIn 0.8s ease-in-out 0.4s",
                      animationFillMode: "backwards",
                    }}
                  >
                    <div className="title">{t("location")}</div>

                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        sx={{ marginRight: "5px" }}
                        control={
                          <Checkbox
                            checked={openLocation}
                            onChange={(event) =>
                              setOpenLocation(event.target.checked)
                            }
                            size="small"
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 16 },
                              color: "#9f7fe3",
                              "&.Mui-checked": {
                                color: "#9f7fe3",
                              },
                            }}
                          />
                        }
                        label={t("open")}
                        componentsProps={{
                          typography: {
                            sx: {
                              fontWeight: "300",
                              fontSize: "12px",
                            },
                          },
                        }}
                      />
                      <Tooltip title={t("openTooltip")} placement="right">
                        <InfoIcon sx={{ color: "#9f7fe3", fontSize: "16px" }} />
                      </Tooltip>
                    </FormGroup>
                  </Box>

                  <TextField
                    inputRef={inputRef}
                    placeholder={t("address")}
                    variant="outlined"
                    sx={{
                      width: "100%",
                      animation: "fadeIn 0.8s ease-in-out 0.5s",
                      animationFillMode: "backwards",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      animation: "fadeIn 0.8s ease-in-out 0.6s",
                      animationFillMode: "backwards",
                    }}
                  >
                    <div className="title">{t("duration")}</div>

                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        sx={{ marginRight: "5px" }}
                        control={
                          <Checkbox
                            checked={openDuration}
                            onChange={(event) =>
                              setOpenDuration(event.target.checked)
                            }
                            size="small"
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 16 },
                              color: "#9f7fe3",
                              "&.Mui-checked": {
                                color: "#9f7fe3",
                              },
                            }}
                          />
                        }
                        label={t("open")}
                        componentsProps={{
                          typography: {
                            sx: {
                              fontWeight: "300",
                              fontSize: "12px",
                            },
                          },
                        }}
                      />
                      <Tooltip title={t("openTooltip")} placement="right">
                        <InfoIcon sx={{ color: "#9f7fe3", fontSize: "16px" }} />
                      </Tooltip>
                    </FormGroup>
                  </Box>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        alignItems: "center",
                        animation: "fadeIn 0.8s ease-in-out 0.7s",
                        animationFillMode: "backwards",
                      }}
                    >
                      <TextField
                        id="outlined-number"
                        placeholder="3"
                        type="number"
                        sx={{ marginRight: "20px", width: "100%" }}
                        value={durationQuantity}
                        onChange={(e) => setDurationQuantity(e.target.value)}
                      />
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={duration}
                          onChange={handleChange}
                        >
                          <MenuItem value={"Hours"}>{t("hours")}</MenuItem>
                          <MenuItem value={"Days"}>{t("days")}</MenuItem>
                          <MenuItem value={"Weeks"}>{t("weeks")}</MenuItem>
                          <MenuItem value={"Months"}>{t("months")}</MenuItem>
                          <MenuItem value={"Years"}>{t("years")}</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    gap: "20px",
                    animation: "fadeInUp 0.8s ease-in-out 0.8s",
                    animationFillMode: "backwards",
                  }}
                >
                  <Button
                    onClick={() => {
                      if (projectLocation) {
                        handleCoordinates();
                      }
                      handleNextStep();
                    }}
                    disabled={disableStepThree}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("next")}
                  </Button>
                  <Button
                    onClick={() => setStep(2)}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("back")}
                  </Button>
                </Grid>
              </Grid>
            )}
            {step === 4 && (
              <Grid
                container
                spacing={4}
                sx={{
                  animation: "fadeIn 0.8s ease-in-out",
                  "@keyframes fadeIn": {
                    "0%": {
                      opacity: 0,
                      transform: "translateY(20px)",
                    },
                    "100%": {
                      opacity: 1,
                      transform: "translateY(0)",
                    },
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    animation: "fadeInLeft 0.8s ease-in-out",
                  }}
                >
                  <div className="section">{t("lead")}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    animation: "fadeInRight 0.8s ease-in-out",
                  }}
                >
                  <div className="title">{t("name")}</div>
                  <TextField
                    sx={{
                      width: "100%",
                      animation: "fadeIn 0.8s ease-in-out 0.2s",
                      animationFillMode: "backwards",
                    }}
                    variant="outlined"
                    value={projectOwner || ""}
                    onChange={(e) => setProjectOwner(e.target.value)}
                  />
                  <div className="title">{t("role")}</div>

                  <Autocomplete
                    disablePortal
                    value={userRole}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      setUserRole(newValue);
                    }}
                    id="combo-box-demo"
                    options={[
                      { RoleName: "Add new item", specialOption: true },
                      ...roleOptions,
                    ]}
                    getOptionLabel={(option) => option.name || ""}
                    sx={{
                      width: "100%",
                      animation: "fadeIn 0.8s ease-in-out 0.3s",
                      animationFillMode: "backwards",
                    }}
                    renderOption={(props, option) => {
                      if (option.specialOption) {
                        return (
                          <CustomOption {...props}>
                            <Button
                              startIcon={<AddCircleIcon />}
                              onClick={(event) => handleUserRoleItem(event)}
                            >
                              Add New Role
                            </Button>
                          </CustomOption>
                        );
                      }
                      return (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Choose Role" />
                    )}
                  />
                  <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openRoleDialog}
                    onClose={handleRoleDialogClose}
                    className="custom-dialog"
                  >
                    <DialogTitle>Add a new role</DialogTitle>
                    <DialogContent>
                      <DialogContentText sx={{ marginBottom: "20px" }}>
                        Did we miss any? Please, add it!
                      </DialogContentText>
                      <TextField
                        autoFocus
                        value={userDialogValue.name}
                        onChange={(event) =>
                          setUserDialogValue({
                            ...userDialogValue,
                            name: event.target.value,
                          })
                        }
                        placeholder="Enter Role"
                        type="text"
                        variant="outlined"
                        fullWidth
                      />
                    </DialogContent>
                    <DialogActions sx={{ padding: "24px" }}>
                      <Button
                        className="dialog-btn"
                        variant="contained"
                        onClick={handleRoleDialogClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        className="dialog-btn"
                        onClick={handleUserRoleSubmit}
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    animation: "fadeInLeft 0.8s ease-in-out 0.4s",
                    animationFillMode: "backwards",
                  }}
                >
                  <div className="section">{t("who")}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    animation: "fadeInRight 0.8s ease-in-out 0.4s",
                    animationFillMode: "backwards",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={selectedPaymentOption === "paid" ? 6 : 11}>
                      <div className="title">{t("roles")}</div>
                      <Autocomplete
                        disablePortal
                        value={currentRole}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          setCurrentRole(newValue);
                        }}
                        options={[
                          { name: "Add new item", specialOption: true },
                          ...roleOptions,
                        ]}
                        getOptionLabel={(option) => option.name || ""}
                        sx={{ width: "100%" }}
                        renderOption={(props, option) => {
                          if (option.specialOption) {
                            return (
                              <CustomOption {...props}>
                                <Button
                                  startIcon={<AddCircleIcon />}
                                  onClick={(event) => handleAddItem(event)}
                                >
                                  Add New Role
                                </Button>
                              </CustomOption>
                            );
                          }
                          return (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Add Role" />
                        )}
                      />
                      <Dialog
                        fullWidth={true}
                        maxWidth={"sm"}
                        open={openDialog}
                        onClose={handleDialogClose}
                        className="custom-dialog"
                      >
                        <DialogTitle>Add a new role</DialogTitle>
                        <DialogContent>
                          <DialogContentText sx={{ marginBottom: "20px" }}>
                            Did we miss any? Please, add it!
                          </DialogContentText>
                          <TextField
                            autoFocus
                            value={userDialogValue.name}
                            onChange={(event) =>
                              setUserDialogValue({
                                ...userDialogValue,
                                name: event.target.value,
                              })
                            }
                            placeholder="Enter Role"
                            type="text"
                            variant="outlined"
                            fullWidth
                          />
                        </DialogContent>
                        <DialogActions sx={{ padding: "24px" }}>
                          <Button
                            className="dialog-btn"
                            variant="contained"
                            onClick={handleDialogClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            className="dialog-btn"
                            onClick={handleUserRoleSubmit}
                          >
                            Add
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                    {selectedPaymentOption === "paid" && (
                      <Grid item xs={5}>
                        <div className="title">{t("budget")}</div>
                        <OutlinedInput
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          label="Amount"
                          fullWidth
                          type="number"
                          value={roleBudget}
                          onChange={(e) => setRoleBudget(e.target.value)}
                        />
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton onClick={handleNewRole} aria-label="add role">
                        <img alt="add icon" src="/images/add_icon.svg"></img>
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "8px",
                      marginTop: "15px",
                      animation: "fadeIn 0.8s ease-in-out 0.5s",
                      animationFillMode: "backwards",
                    }}
                  >
                    {rolesArray &&
                      rolesArray.map((role) => {
                        return (
                          <Chip
                            key={role.id}
                            label={
                              selectedPaymentOption === "paid"
                                ? `${role.name} - $${role.budget}`
                                : role.name
                            }
                            className="role"
                            onDelete={() => handleDelete(role.id)}
                            sx={{
                              "& .MuiChip-deleteIcon": {
                                color: "white",
                              },
                              "& .MuiChip-deleteIcon:hover": {
                                color: "#ffffffc7",
                              },
                            }}
                          />
                        );
                      })}
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    gap: "20px",
                    animation: "fadeInUp 0.8s ease-in-out 0.6s",
                    animationFillMode: "backwards",
                  }}
                >
                  <Button
                    onClick={() => setStep(5)}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("next")}
                  </Button>
                  <Button
                    onClick={() => setStep(3)}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("back")}
                  </Button>
                </Grid>
              </Grid>
            )}
            {step === 5 && (
              <Grid
                container
                spacing={4}
                sx={{
                  animation: "fadeIn 0.8s ease-in-out",
                  "@keyframes fadeIn": {
                    "0%": {
                      opacity: 0,
                      transform: "translateY(20px)",
                    },
                    "100%": {
                      opacity: 1,
                      transform: "translateY(0)",
                    },
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    animation: "fadeInLeft 0.8s ease-in-out",
                  }}
                >
                  <div className="section">{t("images")}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    animation: "fadeInLeft 0.8s ease-in-out",
                  }}
                >
                  <SortableTool sendImages={handleImages} />
                </Grid>

                <Grid item xs={12} md={3}>
                  <div className="section">{t("links")}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    animation: "fadeInLeft 0.8s ease-in-out",
                  }}
                >
                  <div className="title">{t("moodboard")}</div>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Autocomplete
                      multiple
                      defaultValue={[]}
                      options={[]}
                      sx={{
                        width: "100%",
                      }}
                      freeSolo
                      value={links}
                      onChange={(event, newValue) => {
                        setLinks(newValue);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            className="link-chip"
                            label={truncateLabel(option)}
                            sx={{
                              fontSize: "12px",
                              borderRadius: "5px",
                              background: "#fff",
                              border: "1px solid #b3b3b3",
                            }}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Add Link"
                          inputProps={{
                            ...params.inputProps,
                            value: currentLink,
                          }}
                          onChange={(e) => setCurrentLink(e.target.value)}
                        />
                      )}
                    />

                    <IconButton
                      onClick={() => {
                        if (currentLink) {
                          setLinks([...links, currentLink]);
                          setCurrentLink("");
                        }
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "flex-start",
                      gap: "5px",
                      flexWrap: "wrap",
                      marginTop: "10px",
                    }}
                  >
                    {links &&
                      links.map((link) =>
                        ReactPlayer.canPlay(link) ? (
                          <div
                            style={{
                              borderRadius: "5px",
                              width: "100px",
                              height: "80px",
                            }}
                          >
                            {" "}
                            <ReactPlayer
                              url={link}
                              width="100%"
                              height="100%"
                              playing={false}
                              muted={true}
                              controls={false}
                              className="react-player"
                            />
                          </div>
                        ) : (
                          <div
                            className="link-container"
                            onClick={() => openLinkInNewTab(link)}
                          >
                            <LinkIcon></LinkIcon>
                          </div>
                        )
                      )}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    gap: "20px",
                  }}
                >
                  <Button
                    onClick={() => handleNewProject()}
                    className="create-btn"
                    variant="contained"
                  >
                    {selectedPaymentOption === "paid" &&
                    selectedOption === "managed"
                      ? t("submitProposal")
                      : t("createProject")}
                  </Button>
                  <Button
                    onClick={() => handleFinalBack()}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("back")}
                  </Button>
                </Grid>
                {showProgress && (
                  <Grid item xs={12}>
                    <LinearProgress
                      sx={{
                        backgroundColor: "#e9e3f5",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#9f7fe3",
                        },
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default CreateProject;
