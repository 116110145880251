import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";

const TrustAndSafety = () => {
  const { t } = useTranslation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "600px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "3rem",
          gap: "1rem",
          marginBottom: "3rem",
        }}
      >
        <div
          style={{
            fontSize: "28px",
            fontWeight: "500",
          }}
        >
          Trust, Safety & Security
        </div>
        <div style={{ fontSize: "10px", color: "#808080" }}>
          Effective November 14th 2024
        </div>
        <Divider sx={{ marginBottom: "1rem" }} />
        <div style={{ fontSize: "12px", lineHeight: "2" }}>
          At Togather, your safety and security are our highest priorities.
          We've built our platform with robust safeguards to ensure you can
          connect and collaborate with confidence. Our comprehensive trust and
          safety measures are designed to protect our community members while
          fostering meaningful interactions. <br /> <br />
          Security is at the core of everything we do. We employ
          industry-leading encryption standards to protect your personal
          information and data. Our systems are regularly updated and monitored
          to guard against potential threats, ensuring your information remains
          secure. We comply with all relevant data protection regulations and
          maintain strict privacy controls to safeguard your confidential
          information. <br /> <br />
          We believe trust is earned through transparency and consistent action.
          Our team proactively monitors platform activity to prevent misuse and
          maintain a respectful environment for all users. We have clear
          community guidelines that all members must follow, and we take swift
          action against any violations. If you encounter any concerns about
          safety or security while using Togather, please report them
          immediately to info@createtogather.com. <br /> <br />
          Our commitment to safety extends beyond just digital security. We
          carefully verify user information and implement multiple layers of
          authentication to maintain platform integrity. Regular security audits
          and updates help us stay ahead of emerging threats and ensure we're
          providing the safest possible environment for our community. <br />{" "}
          <br />
          Your trust in Togather drives us to maintain the highest standards of
          safety and security. We continuously invest in advanced security
          measures and update our protocols based on evolving best practices.
          Together, we're building a secure and trustworthy platform where
          connections can flourish and communities can thrive.
        </div>
      </div>
    </Box>
  );
};

export default TrustAndSafety;
