import React from "react";
import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import Chip from "@mui/material/Chip";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import PersonIcon from "@mui/icons-material/Person";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import PostAddIcon from "@mui/icons-material/PostAdd";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import { supabase } from "./SupabaseClient";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DialogContentText from "@mui/material/DialogContentText";
import { useTranslation } from "react-i18next";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import NotesIcon from "@mui/icons-material/Notes";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { UserContext } from "./UserContext";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  const nameParts = name.split(" ");
  const initials =
    nameParts.length > 1
      ? `${nameParts[0][0]}${nameParts[1][0]}`
      : `${nameParts[0][0]}`;

  return {
    sx: {
      bgcolor: stringToColor(name),
      cursor: "pointer",
    },
    children: initials.toUpperCase(),
  };
}

const ProposalManagement = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [project, setProject] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [proposalId, setProposalId] = useState("");
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDeleteProjectDialog, setOpenDeleteProjectDialog] = useState(false);
  const { t, i18n } = useTranslation();
  const [status, setStatus] = useState("");
  const [proposals, setProposals] = useState([]);
  const [hireManagerDialog, setHireManagerDialog] = useState(false);
  const [approvedBudget, setApprovedBudget] = useState("");
  const [projectManagers, setProjectManagers] = useState([]);
  const { user, loading } = useContext(UserContext);

  const handleClickOpen = (note) => {
    setOpen(true);
    setNote(note);
  };

  const handlePlayClick = async () => {
    if (project.type === "collab") {
      const { data: status, error: statusError } = await supabase
        .from("Projects")
        .update({ status: "live" })
        .eq("id", projectId);

      if (statusError) {
        console.log(statusError);
      } else {
        setStatus("live");
        return;
      }
    }
  };

  const getProject = async () => {
    const { data } = await supabase
      .from("Projects")
      .select(
        `
        name, type,
        Users (id, first_name, last_name, profile_picture, email)
      `
      )
      .eq("id", projectId);

    if (data) {
      setProject(data[0]);
    }
  };

  const getProjectManagers = async () => {
    const { data } = await supabase
      .from("Users")
      .select("first_name, last_name, profile_picture, id, email")
      .eq("verified_manager", true);

    if (data) {
      setProjectManagers(data);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getProject();
    getProjectManagers();
  }, []);

  const handleRemoveTeamMember = async (roleId, userId) => {
    const { data, error } = await supabase
      .from("ProjectRoles")
      .update({ user: null, filled: false })
      .eq("id", roleId);

    if (error) {
      console.log(error);
    } else {
      const { data: applications, error: applicationsError } = await supabase
        .from("Applications")
        .update({ approved: false })
        .eq("role", roleId)
        .eq("user", userId);
      if (applicationsError) {
        console.log(applicationsError);
      } else {
        getProject();
      }
    }
  };

  const handleHire = async (manager) => {
    const { data, error } = await supabase
      .from("Projects")
      .update({ proposal_stage: "complete" })
      .eq("id", projectId);
    if (error) {
      console.log(error);
    }

    const { data: projectRole, error: projectRoleError } = await supabase
      .from("ProjectRoles")
      .insert({
        user: manager.id,
        filled: true,
        project_id: projectId,
        name: "Project Manager",
      });

    if (projectRoleError) {
      console.log(projectRoleError);
    }

    try {
      const response = await fetch(
        "https://54rr0rbk9h.execute-api.us-east-1.amazonaws.com/prod/manager-match-notifications",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            projectId: projectId,
            managerId: manager.id,
            recipientEmail: project.Users.email,
            managerName: `${manager.first_name} ${manager.last_name}`,
            managerImage: manager.profile_picture,
            projectUrl: `https://www.createtogather.com/project/management/${projectId}`,
            language: i18n.language,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("API Gateway call failed");
      }

      navigate(`/project/management/${projectId}`);
    } catch (error) {
      console.error("Error calling API Gateway:", error);
    }
  };

  // const handleMessage = (id) => {
  //   navigate(`/chat`, { state: { recipientId: id } });
  // };

  const handleDelete = async () => {
    const { data, error } = await supabase
      .from("Projects")
      .delete()
      .eq("id", projectId);

    if (error) {
      console.log(error);
    } else {
      navigate("/projects");
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {project ? (
        <Box
          className="custom-margins-submissions"
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "3rem",
            paddingTop: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <Box>
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  gap: "0.5rem",
                }}
              >
                <IconButton size="medium" onClick={handleBack}>
                  <NavigateBeforeIcon />
                </IconButton>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontWeight: "400",
                    fontSize: "26px",
                  }}
                >
                  {project.name}
                </Typography>
                <Tooltip title="Start Project" placement="top">
                  <IconButton
                    sx={{ color: "#9f7fe3" }}
                    onClick={handlePlayClick}
                  >
                    <PlayCircleFilledIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit Project" placement="top">
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      navigate(`/editproject/${projectId}`);
                    }}
                  >
                    <EditRoundedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Project" placement="top">
                  <IconButton
                    aria-label="delete"
                    onClick={() => setOpenDeleteProjectDialog(true)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={openDeleteProjectDialog}
                  onClose={() => setOpenDeleteProjectDialog(false)}
                  aria-labelledby="confirm-dialog-title"
                  aria-describedby="confirm-dialog-description"
                  PaperProps={{
                    sx: {
                      padding: "10px", // Add padding to the entire dialog
                    },
                  }}
                >
                  <DialogTitle id="confirm-dialog-title">
                    {t("deleteProject")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                      {t("deleteProjectPrompt")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      sx={{ fontSize: "10px !important" }}
                      onClick={() => setOpenDeleteProjectDialog(false)}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      sx={{ fontSize: "10px !important" }}
                      onClick={() => {
                        handleDelete();
                        setOpenDeleteProjectDialog(false);
                      }}
                      color="primary"
                      autoFocus
                    >
                      {t("confirm")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Box>
            <Box sx={{ marginTop: "30px" }}>
              <Container
                sx={{
                  padding: "0px !important",
                  height: "100%",
                  margin: "0px !important",
                  maxWidth: "1280px !important",
                  width: "100%",
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    textAlign: "left",
                    fontWeight: "300",
                    fontSize: "14px",
                  }}
                >
                  {t("proposals")}
                </Typography>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  {projectManagers.map((manager) => (
                    <div
                      key={manager.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "160px",
                        padding: "20px",
                        gap: "12px",
                        backgroundColor: "#fff",
                        borderRadius: "12px",
                        boxShadow: "0 4px 6px rgba(0,0,0,0.06)",
                        margin: "10px 0",

                        transition: "all 0.3s ease",
                        border: "1px solid rgba(0,0,0,0.08)",
                        "&:hover": {
                          transform: "translateY(-4px)",
                          boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                          borderColor: "rgba(0,0,0,0.12)",
                        },
                      }}
                    >
                      <Avatar
                        className="avatar"
                        onClick={() =>
                          window.open(`/profile/${manager.id}`, "_blank")
                        }
                        {...stringAvatar(
                          `${manager.first_name} ${manager.last_name}`
                        )}
                        src={manager.profile_picture}
                        sx={{
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                        }}
                      />
                      <div
                        style={{
                          fontSize: "16px",
                          color: "#333",
                          fontWeight: "300",
                        }}
                      >{`${manager.first_name} ${manager.last_name}`}</div>
                      <div
                        style={{
                          backgroundColor: "rgb(227 214 255)",
                          color: "#9f7fe3",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          fontSize: "10px",
                          fontWeight: "500",
                        }}
                      >
                        Verified Manager
                      </div>
                      {/* <Button
                      size="small"
                      sx={{
                        backgroundColor: "#ffffff",
                        ":hover": {
                          bgcolor: "#ffffffd8",
                        },
                        borderRadius: "10px",
                        color: "#000000",
                        fontWeight: "400",
                        fontSize: "10px",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 1px",
                      }}
                      onClick={() => handleClickOpen(manager.note)}
                      variant="contained"
                      startIcon={<NotesIcon sx={{ height: "10px" }} />}
                    >
                      Note
                    </Button> */}
                      <div style={{ display: "flex", gap: "10px" }}>
                        {/* <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#ffffff",
                          color: "#000000",
                          textTransform: "none",
                          fontSize: "10px",
                          fontWeight: "400",
                          padding: "2px 2px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                          },
                          border: "0.5px solid #000000",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMessage(manager.id);
                        }}
                      >
                        Message
                      </Button> */}
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#ffffff",
                            color: "#000000",
                            textTransform: "none",
                            fontSize: "10px",
                            fontWeight: "400",
                            padding: "2px 2px",
                            "&:hover": {
                              backgroundColor: "#ffffff",
                            },
                            border: "0.5px solid #000000",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleHire(manager);
                            // setHireManagerDialog(true);
                            // setCandidateId(manager.id);
                          }}
                        >
                          Hire
                        </Button>
                      </div>
                    </div>
                  ))}
                </Box>
                <Dialog
                  onClose={() => setOpen(false)}
                  open={open}
                  PaperProps={{
                    sx: {
                      padding: "10px",
                      width: "500px", // Add padding to the entire dialog
                    },
                  }}
                >
                  <DialogTitle>Custom Note</DialogTitle>
                  <DialogContent>
                    <DialogContentText>{note}</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      sx={{ fontSize: "10px" }}
                      onClick={() => setOpen(false)}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                {projectManagers.length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "3rem",
                    }}
                  >
                    <div style={{ maxWidth: "400px", width: "100%" }}>
                      <DotLottieReact
                        src={
                          "https://noozjeclpgnzvbgdtser.supabase.co/storage/v1/object/public/media/no_results.lottie"
                        }
                        loop
                        autoplay
                      />
                    </div>

                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#c7c7c7",
                      }}
                    >
                      {t("noProposals")}
                    </Typography>
                  </div>
                )}
                <Dialog
                  open={hireManagerDialog}
                  onClose={() => setHireManagerDialog(false)}
                  PaperProps={{
                    sx: {
                      padding: "20px",
                      width: "400px",
                    },
                  }}
                >
                  <DialogTitle id="confirm-dialog-title">
                    {t("confirmHire")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                      {t("enterBudget")}
                    </DialogContentText>
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      sx={{ marginTop: "20px", height: "40px" }}
                      type="number"
                      value={approvedBudget}
                      onChange={(e) => setApprovedBudget(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      sx={{ fontSize: "10px !important" }}
                      onClick={() => setHireManagerDialog(false)}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        handleHire();
                        setHireManagerDialog(false);
                      }}
                      color="primary"
                      autoFocus
                      sx={{ fontSize: "10px !important" }}
                    >
                      {t("hire")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Container>
            </Box>
          </Box>
        </Box>
      ) : (
        <Stack
          className="custom-margins"
          spacing={4}
          sx={{
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
        >
          <Skeleton variant="rounded" width={300} height={70} />
          <Skeleton variant="rounded" width={600} height={40} />

          <Skeleton variant="rounded" width={1280} height={300} />
        </Stack>
      )}
    </Box>
  );
};

export default ProposalManagement;
