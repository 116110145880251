import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Lightbox from "yet-another-react-lightbox";
import Button from "@mui/material/Button";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SortableTool from "./Sortable";
import { handleUpload, deleteFromStorageAndDB } from "./imageUtils";
import { supabase } from "./SupabaseClient";
import { useTranslation } from "react-i18next";
import MessageIcon from "@mui/icons-material/Message";
import { useNavigate } from "react-router-dom";
import PlaceIcon from "@mui/icons-material/Place";
import TextField from "@mui/material/TextField";

const Profile = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [value, setValue] = useState(0);
  const [index, setIndex] = useState(-1);
  const [showOptions, setShowOptions] = useState(true);
  const [user, setUser] = useState({});
  const [images, setImages] = useState([]);
  const [lightBox, setLightBox] = useState([]);
  //const [following, setFollowing] = useState(false);
  const [open, setOpen] = useState(false);
  const [portfolioImages, setPortfolioImages] = useState([]);
  const { t } = useTranslation();
  const [bio, setBio] = useState("");
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleImageClick = (index) => {
    setIndex(index);
  };

  const transformArrayLightBox = (array) => {
    return array.map((item, index) => ({
      src: item,
    }));
  };

  const transformArrayImageList = (array) => {
    return array.map((item, index) => ({
      img: item,
    }));
  };

  const handleImages = (data) => {
    setPortfolioImages(data);
  };

  const handleEditPortfolio = async () => {
    try {
      const mediaUrls = await handleUpload({
        images: portfolioImages,
        profile: currentUser.sub,
      }).catch(console.error);

      const { error: updateError } = await supabase
        .from("Users")
        .update({ media: mediaUrls, bio: bio })
        .eq("id", currentUser.sub);

      if (updateError) {
        throw updateError;
      }
      setImages(transformArrayImageList(mediaUrls));
      setLightBox(transformArrayLightBox(mediaUrls));
      setOpen(false);
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const { data, error } = await supabase
        .from("Users")
        .select("*")
        .eq("id", userId);

      if (data) {
        setUser(data[0]);
        if (data[0].media) {
          setImages(transformArrayImageList(data[0].media));
          setLightBox(transformArrayLightBox(data[0].media));
        }
      }
    };
    getUser();
    checkUser();
  }, [userId]);

  const checkUser = async () => {
    if (currentUser && currentUser.sub === userId) {
      setShowOptions(false);
      // } else if (currentUser && currentUser.sub !== userId) {
      //   const { data, error } = await supabase
      //     .from("Followers")
      //     .select("*")
      //     .eq("follower_id", currentUser.sub) // Replace followerId with the actual follower's user ID
      //     .eq("following_id", userId); // Replace followingId with the actual followed user's ID

      //   if (error) console.error("Error checking follow status:", error);
      //   else if (data.length > 0) setFollowing(true);
      //   else setFollowing(false);
    } else {
      setShowOptions(true);
    }
  };

  // const handleUnfollow = async () => {
  //   setFollowing(false);
  //   const { data, error } = await supabase
  //     .from("Followers")
  //     .delete()
  //     .eq("follower_id", currentUser.sub)
  //     .eq("following_id", userId);

  //   if (error) {
  //     setFollowing(true);
  //     console.error("Error removing follow relationship:", error);
  //   }
  // };

  // const handleFollow = async () => {
  //   setFollowing(true);
  //   const { data, error } = await supabase
  //     .from("Followers")
  //     .insert([{ follower_id: currentUser.sub, following_id: userId }]);

  //   if (error) {
  //     setFollowing(false);
  //     console.error("Error adding follow relationship:", error);
  //   }
  // };

  const handleMessage = () => {
    navigate(`/chat`, { state: { recipientId: userId } });
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        className="custom-margins"
        sx={{
          paddingBottom: "3rem",
          paddingTop: "2rem",
        }}
      >
        {" "}
        <Container
          sx={{
            padding: "20px 0px 20px 0px !important",
            height: "auto",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "400px",
          }}
        >
          <Avatar
            sx={{ width: 100, height: 100 }}
            alt={user.userName}
            src={user.profile_picture ? user.profile_picture : null}
          />
          <Typography
            variant="h5"
            sx={{
              marginTop: "20px",
              fontWeight: 300,
            }}
          >
            {user && user.first_name && `${user.first_name} ${user.last_name}`}
          </Typography>
          {user.role && (
            <Typography
              variant="subtitle1"
              sx={{
                marginTop: "20px",
                fontWeight: 300,
              }}
            >
              {user.role}
            </Typography>
          )}

          {user.city && (
            <div
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <PlaceIcon sx={{ width: "14px", height: "14px" }} />
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 300,
                }}
              >
                {user.city}
              </Typography>
            </div>
          )}

          {user.bio && (
            <Typography
              variant="caption"
              sx={{ marginTop: "10px", fontWeight: 300 }}
            >
              {user.bio}
            </Typography>
          )}

          {!showOptions && (
            <Button
              onClick={handleClickOpen}
              sx={{
                color: "#000000 !important",
                backgroundColor: "#ffffff !important",
                borderRadius: "14px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 1px",
                fontWeight: "300",
                fontSize: "10px",
                marginTop: "1rem",
              }}
              variant="contained"
              startIcon={<EditIcon sx={{ width: "14px", height: "14px" }} />}
            >
              Edit
            </Button>
          )}

          {showOptions && (
            <Box sx={{ display: "flex", gap: "20px" }}>
              {/* {following ? (
                <Button
                  onClick={handleUnfollow}
                  sx={{
                    backgroundColor: "#9f7fe3",
                    ":hover": {
                      bgcolor: "#9f7fe3d8",
                    },
                    borderRadius: "22px",
                    marginTop: "20px",
                  }}
                  variant="contained"
                >
                  Following
                </Button>
              ) : (
                <Button
                  onClick={handleFollow}
                  sx={{
                    backgroundColor: "#9f7fe3",
                    ":hover": {
                      bgcolor: "#9f7fe3d8",
                    },
                    borderRadius: "22px",
                    marginTop: "20px",
                  }}
                  variant="contained"
                  startIcon={<PersonAddAltIcon />}
                >
                  Follow
                </Button>
              )} */}

              <Button
                onClick={handleMessage}
                sx={{
                  backgroundColor: "#ffffff",
                  ":hover": {
                    bgcolor: "#ffffffd8",
                  },
                  borderRadius: "22px",
                  marginTop: "20px",
                  color: "#000000",
                  fontWeight: "300",
                  fontSize: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 1px",
                }}
                variant="contained"
                startIcon={
                  <MessageIcon sx={{ width: "14px", height: "14px" }} />
                }
              >
                Message
              </Button>
            </Box>
          )}
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                padding: "10px", // Add padding to the entire dialog
              },
            }}
          >
            <DialogTitle>Edit Portfolio</DialogTitle>
            <DialogContent>
              <DialogContentText>Bio</DialogContentText>
              <TextField
                multiline
                rows={10}
                fullWidth
                sx={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#9f7fe3",
                      borderWidth: "1px",
                    },
                  },
                }}
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                placeholder="Enter short bio about yourself"
              />
              <DialogContentText sx={{ marginBottom: "1rem" }}>
                Media
              </DialogContentText>
              <SortableTool
                sendImages={handleImages}
                initialData={
                  user.media && user.media.length > 0 ? user.media : []
                }
              />
            </DialogContent>
            <DialogActions>
              <Button sx={{ fontSize: "10px" }} onClick={handleClose}>
                Cancel
              </Button>
              <Button sx={{ fontSize: "10px" }} onClick={handleEditPortfolio}>
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
        <Container sx={{ paddingBottom: "40px", marginTop: "40px" }}>
          <ImageList variant="masonry" cols={3} gap={4}>
            {images &&
              images.length > 0 &&
              images.map((item, index) => (
                <ImageListItem
                  key={index}
                  onClick={() => handleImageClick(index)}
                  sx={{ cursor: "pointer" }}
                >
                  <img
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.img}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
          </ImageList>

          {lightBox && lightBox.length > 0 && (
            <Lightbox
              slides={lightBox}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              // enable optional lightbox plugins
            />
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Profile;
