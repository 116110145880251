// MapComponent.js
import React, { useEffect, useRef } from "react";

const Map = ({ center, zoom }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    // Initialize the map when the component mounts
    const map = new window.google.maps.Map(mapRef.current, {
      center: center,
      zoom: zoom,
      disableDefaultUI: true, // Disables the default map controls
      zoomControl: true,
    });

    // Add a marker
    new window.google.maps.Marker({
      position: center,
      map: map,
    });
  }, [center, zoom]);

  return <div ref={mapRef} className="map-container" />;
};

export default Map;
