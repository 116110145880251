import React from "react";
import { Avatar, Badge, Typography, Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

// Styled component for the small overlapping avatar
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const MessageContainer = ({
  thread,
  currentUserId,
  onClick,
  currentThreadId,
  isMobile,
}) => {
  const { participants, latestMessage, unreadCount, groupId } = thread;

  // Filter out the current user from participants to display others
  const otherParticipants = participants.filter(
    (participant) => participant.user_id !== currentUserId
  );

  // Construct display name using first_name(s) of other participants
  const displayName =
    otherParticipants.map((participant) => participant.first_name).join(", ") ||
    "No Recipients";

  // Format the latest message time
  const messageTime = latestMessage
    ? dayjs(latestMessage.created_at).format("h:mm A")
    : "";

  // Avatars
  let avatarContent;
  if (otherParticipants.length === 1) {
    // One-on-one chat, show recipient's avatar
    avatarContent = (
      <Avatar
        alt={otherParticipants[0].first_name}
        src={otherParticipants[0].profile_image_url}
      />
    );
  } else if (otherParticipants.length > 1) {
    // Group chat, show overlapped avatars of first two participants
    avatarContent = (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <SmallAvatar
            alt={otherParticipants[1].first_name}
            src={otherParticipants[1].profile_image_url}
            sx={{ fontSize: "12px" }}
          />
        }
      >
        <Avatar
          alt={otherParticipants[0].first_name}
          src={otherParticipants[0].profile_image_url}
          sx={{ fontSize: "12px" }}
        />
      </Badge>
    );
  } else {
    // No other participants
    avatarContent = <Avatar />;
  }

  return (
    <Box
      className="messageContainer"
      onClick={onClick}
      sx={{
        backgroundColor:
          !isMobile && currentThreadId === groupId ? "#f5f5f5" : "inherit",
      }}
    >
      <Container
        className="noPadding"
        sx={{ width: "auto", marginRight: "20px" }}
      >
        {avatarContent}
      </Container>
      <Container className="noPadding" sx={{ textAlign: "left" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
          {displayName}
        </Typography>
        <Typography
          variant="caption"
          sx={{ fontWeight: "400", textTransform: "none" }}
        >
          {latestMessage ? latestMessage.content : "No messages yet"}
        </Typography>
      </Container>
      <Container
        className="noPadding"
        sx={{
          textAlign: "right",
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
      >
        <Typography
          variant="overline"
          gutterBottom
          sx={{
            fontWeight: "400",
            textTransform: "none",
            color: "rgba(124, 124, 124, 1)",
          }}
        >
          {messageTime}
        </Typography>
        {unreadCount > 0 && (
          <Avatar
            sx={{
              bgcolor: "#9f7fe3",
              width: "18px",
              height: "18px",
              fontSize: "10px",
            }}
            alt="unread"
          >
            {unreadCount}
          </Avatar>
        )}
      </Container>
    </Box>
  );
};

export default MessageContainer;
