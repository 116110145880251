import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";

const Cookies = () => {
  const { t } = useTranslation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "600px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "3rem",
          gap: "1rem",
        }}
      >
        <div
          style={{
            fontSize: "28px",
            fontWeight: "500",
          }}
        >
          Cookies
        </div>
        <div style={{ fontSize: "10px", color: "#808080" }}>
          Effective November 14th 2024
        </div>
        <Divider />
        <div style={{ fontSize: "12px", lineHeight: 2 }}>
          <section>
            <h2>What Are Cookies?</h2>
            <p>
              Cookies are small text files stored on your device by websites you
              visit. They help websites remember information about your visit,
              such as your preferred language, login details, and other
              settings. Togather uses cookies to improve your experience, making
              it easier to navigate our platform and enabling us to provide
              tailored content.
            </p>
          </section>

          <section>
            <h2>How We Use Cookies</h2>
            <p>
              Togather uses cookies to enhance your experience on our platform.
              Cookies help us understand how you interact with our site, enable
              certain functions, and improve your overall experience. Some
              cookies are essential to the functionality of the platform, while
              others allow us to analyze performance and improve the quality of
              our services.
            </p>
          </section>

          <section>
            <h2>Types of Cookies We Use</h2>
            <ul>
              <li>
                <strong>Essential Cookies:</strong> These cookies are necessary
                for the operation of our platform. They enable basic functions
                like page navigation and access to secure areas. Without these
                cookies, the platform may not function properly.
              </li>
              <li>
                <strong>Performance and Analytics Cookies:</strong> These
                cookies allow us to measure and analyze how you use Togather so
                that we can improve functionality and optimize the user
                experience.
              </li>
              <li>
                <strong>Functionality Cookies:</strong> These cookies allow
                Togather to remember choices you make (such as your language or
                region) to provide enhanced, more personalized features.
              </li>
              <li>
                <strong>Advertising Cookies:</strong> We may use these cookies
                to deliver relevant ads to you and measure the effectiveness of
                our advertising campaigns. These cookies track your browsing
                habits to display targeted advertisements based on your
                interests.
              </li>
            </ul>
          </section>

          <section>
            <h2>Managing Cookies</h2>
            <p>
              You have the option to control and manage your cookies. Most
              browsers allow you to change your cookie settings, enabling you to
              block or delete cookies if you prefer. Note that blocking
              essential cookies may affect the functionality of Togather, and
              certain features may not be available.
            </p>
            <p>
              To learn more about managing cookies or for instructions on how to
              modify your browser settings, please refer to your browser’s help
              documentation. You can also visit{" "}
              <a
                href="https://www.allaboutcookies.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                All About Cookies
              </a>{" "}
              for more information on managing and deleting cookies.
            </p>
          </section>

          <section>
            <h2>Changes to This Cookies Policy</h2>
            <p>
              Togather may update this Cookies Policy from time to time to
              reflect changes in technology or regulations. Any updates will be
              posted on this page, and we encourage you to review it
              periodically to stay informed about our use of cookies.
            </p>
          </section>

          <section>
            <h2>Contact Us</h2>
            <p>
              If you have any questions about this Cookies Policy or our use of
              cookies, please contact us at:
            </p>
            <p>
              <strong>Email:</strong>{" "}
              <a href="mailto:info@createtogather.com">
                info@createtogather.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </Box>
  );
};

export default Cookies;
