// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { getLocation } from "./ipService";

const getInitialLanguage = async () => {
  const location = await getLocation();
  if (location && location.country) {
    if (location.country === "KR") {
      return "ko";
    }
  }
  return "en";
};

export const initI18n = async () => {
  const initialLanguage = await getInitialLanguage();

  await i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng: initialLanguage,
      supportedLngs: ["en", "ko"],
      fallbackLng: "en",
      debug: false,
      backend: {
        loadPath: "/locales/{{lng}}/translation.json",
      },
      detection: {
        order: [
          "querystring",
          "cookie",
          "localStorage",
          "navigator",
          "htmlTag",
          "path",
          "subdomain",
        ],
        caches: ["localStorage", "cookie"],
      },
      react: {
        useSuspense: false,
      },
    });
};

export default i18n;
