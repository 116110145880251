// UserContext.js
import React, { createContext, useState, useEffect } from "react";
import { supabase } from "./SupabaseClient"; // Adjust the import path accordingly

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user"))); // User data including profile_picture
  const [loading, setLoading] = useState(true);

  const fetchUser = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (!userData) {
      setLoading(false);
      return;
    }

    const { data, error } = await supabase
      .from("Users")
      .select("*")
      .eq("id", userData.sub)
      .single();

    if (error) {
      console.error("Error fetching user data:", error);
    } else {
      setUser(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();

    // Add event listener for login
    window.addEventListener("userLogin", fetchUser);

    // Cleanup
    return () => {
      window.removeEventListener("userLogin", fetchUser);
    };
  }, []);

  const updateUser = (updatedData) => {
    setUser((prevUser) => ({ ...prevUser, ...updatedData }));
  };

  return (
    <UserContext.Provider value={{ user, updateUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};
