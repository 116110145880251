import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "600px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "3rem",
          gap: "1rem",
          marginBottom: "3rem",
        }}
      >
        <div
          style={{
            fontSize: "28px",
            fontWeight: "500",
          }}
        >
          Privacy Policy
        </div>
        <div style={{ fontSize: "10px", color: "#808080" }}>
          Effective November 14th 2024
        </div>
        <Divider />
        <div style={{ fontSize: "12px", lineHeight: 2 }}>
          <p>
            Welcome to Togather! Your privacy is important to us. This Privacy
            Policy explains how Togather (“we,” “our,” or “us”) collects, uses,
            shares, and protects information about you when you use our platform
            and services. By using Togather, you agree to the collection and use
            of your information as outlined in this policy.
          </p>

          <section>
            <h2>1. Information We Collect</h2>
            <p>
              We collect various types of information in connection with
              providing and improving Togather’s services.
            </p>
            <ul>
              <li>
                <strong>Personal Information:</strong> We may collect personal
                data that identifies you, such as your name, email address,
                contact details, payment information, and other information when
                you create an account or use our services.
              </li>
              <li>
                <strong>Non-Personal Information:</strong> We also collect
                non-personal data that does not identify you directly, including
                device details, IP addresses, usage patterns, and browsing
                actions.
              </li>
              <li>
                <strong>Automatic Information Collection:</strong> We use
                technologies like cookies, web beacons, and similar tools to
                collect information automatically as you navigate our platform.
                This may include information on browsing behavior, usage trends,
                and other diagnostic data.
              </li>
            </ul>
          </section>

          <section>
            <h2>2. How We Use Your Information</h2>
            <p>
              We use your information to deliver, support, and enhance our
              services, as well as to communicate with you about relevant
              updates.
            </p>
            <ul>
              <li>
                <strong>Providing Services:</strong> We use your information to
                operate Togather, manage your account, process transactions, and
                provide customer support.
              </li>
              <li>
                <strong>Improving and Personalizing Services:</strong> Data
                helps us understand user preferences, enabling us to enhance our
                services, customize experiences, and optimize our platform.
              </li>
              <li>
                <strong>Communicating with You:</strong> We may use your
                information to send transactional communications, respond to
                inquiries, and notify you of updates, promotions, or policy
                changes.
              </li>
              <li>
                <strong>Ensuring Security:</strong> We use information for fraud
                prevention, to maintain the security of our platform, and to
                protect against unauthorized access.
              </li>
            </ul>
          </section>

          <section>
            <h2>3. How We Share Your Information</h2>
            <p>
              Togather only shares your information under specific circumstances
              and with your privacy in mind.
            </p>
            <ul>
              <li>
                <strong>With Third-Party Service Providers:</strong> We work
                with trusted service providers (e.g., payment processors,
                analytics providers) to help us operate our platform. These
                third parties only have access to your information to perform
                these tasks on our behalf and are obligated to protect it.
              </li>
              <li>
                <strong>For Legal Reasons:</strong> We may disclose your
                information if required to do so by law, or in response to a
                valid legal request, to protect our rights, or to ensure the
                safety of our users and platform.
              </li>
              <li>
                <strong>Business Transfers:</strong> In the event of a merger,
                acquisition, or asset sale, your information may be transferred
                to the new entity. We will provide notice before any personal
                information is transferred and becomes subject to a different
                privacy policy.
              </li>
            </ul>
          </section>

          <section>
            <h2>4. Your Rights and Choices</h2>
            <p>
              Togather respects your rights concerning your data and offers you
              control over how your information is used.
            </p>
            <ul>
              <li>
                <strong>Access and Update:</strong> You can access and update
                your personal information through your account settings or by
                contacting us.
              </li>
              <li>
                <strong>Data Portability and Deletion:</strong> You have the
                right to request a copy of your data or to ask us to delete your
                information, subject to legal or contractual requirements.
                Please contact us to exercise these rights.
              </li>
              <li>
                <strong>Opt-Out Options:</strong> You can manage your
                preferences for marketing communications or opt out entirely. To
                opt-out, follow the unsubscribe link in any promotional email,
                or contact us directly. You can also manage cookie preferences
                through your browser settings.
              </li>
            </ul>
          </section>

          <section>
            <h2>5. Cookies and Tracking Technologies</h2>
            <p>
              Togather uses cookies and other tracking technologies to collect
              information and personalize your experience.
            </p>
            <ul>
              <li>
                <strong>Types of Cookies Used:</strong> We use essential cookies
                for the platform’s functionality, analytics cookies to
                understand usage patterns, and personalization cookies to
                improve user experience.
              </li>
              <li>
                <strong>Managing Cookies:</strong> Most web browsers allow you
                to control cookies through their settings. You can set your
                browser to block or alert you about cookies, but some parts of
                our platform may not function properly without them.
              </li>
            </ul>
          </section>

          <section>
            <h2>6. Security of Your Information</h2>
            <p>
              We prioritize the security of your data and implement measures to
              safeguard it.
            </p>
            <ul>
              <li>
                <strong>Data Protection Measures:</strong> We use encryption,
                secure access controls, and other protocols to protect your
                information. While we strive to secure your data, no online
                platform is entirely secure, and we cannot guarantee absolute
                security.
              </li>
            </ul>
          </section>

          <section>
            <h2>7. Data Retention</h2>
            <p>
              We retain your personal information as long as necessary to
              fulfill the purposes outlined in this policy, unless a longer
              retention period is required by law.
            </p>
            <p>
              <strong>Retention Criteria:</strong> Retention periods are based
              on factors such as the duration of your account’s active status,
              legal requirements, and our ongoing need to provide services.
            </p>
          </section>

          <section>
            <h2>8. Children’s Privacy</h2>
            <p>
              Togather does not knowingly collect personal information from
              children under the age of [13 or 16, depending on applicable law].
              If we become aware of any such data collected without verification
              of parental consent, we will delete it promptly.
            </p>
          </section>

          <section>
            <h2>9. International Data Transfers</h2>
            <p>
              Togather operates globally, and your information may be
              transferred to and processed in countries outside of your own. We
              take steps to ensure that data transfers comply with relevant data
              protection laws.
            </p>
            <ul>
              <li>
                <strong>Legal Basis for Transfers:</strong> For users in the EU,
                we implement appropriate safeguards, such as Standard
                Contractual Clauses, to protect personal data transferred
                internationally.
              </li>
            </ul>
          </section>

          <section>
            <h2>10. Changes to This Privacy Policy</h2>
            <p>
              We may update this Privacy Policy periodically. If changes are
              significant, we will provide notice via email or on our platform.
            </p>
            <p>
              <strong>Reviewing Updates:</strong> We encourage you to review
              this policy from time to time to stay informed about how we
              protect your information.
            </p>
          </section>

          <section>
            <h2>11. Contact Us</h2>
            <p>
              For questions or concerns about this Privacy Policy or our data
              practices, please contact us at:
            </p>
            <p>
              <strong>Email:</strong>{" "}
              <a href="mailto:info@createtogather.com">
                info@createtogather.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </Box>
  );
};

export default PrivacyPolicy;
