import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Home from "./components/Home";
import Authentication from "./components/Authentication";
import ProjectPage from "./components/ProjectPage";
import Chat from "./components/Chat";
import Settings from "./components/Settings";
import GeneralSettings from "./components/GeneralSettings";
import PaymentSettings from "./components/PaymentSettings";
import Profile from "./components/Profile";
import MyProjects from "./components/MyProjects";
import CreateProject from "./components/CreateProject";
import PrivateRoute from "./components/PrivateRoute";
import Favorites from "./components/Favorites";
import ProjectManagement from "./components/ProjectManagement";
import Proposal from "./components/Proposal";
import ViewSubmissions from "./components/ViewSubmissions";
import EditProject from "./components/EditProject";
import MyProposals from "./components/MyProposals";
import TopAppBar from "./components/TopAppBar";
import { APIProvider } from "@vis.gl/react-google-maps";
import { UserProvider } from "./components/UserContext";
import Footer from "./components/Footer";
import ProposalManagement from "./components/ProposalManagement";
import Feedback from "./components/Feedback";
import HelpAndSupport from "./components/HelpAndSupport";
import TrustAndSafety from "./components/TrustAndSafety";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import Cookies from "./components/Cookies";

const App = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  return (
    <ThemeProvider theme={theme}>
      <APIProvider
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={["places"]}
      >
        <UserProvider>
          <div className="App-container">
            {isLoginPage ? null : <TopAppBar />}
            <div className="Content-container">
              <Routes>
                <Route path="/login" element={<Authentication />} />
                <Route path="/" element={<Home />} />
                <Route path="/project/:projectId" element={<ProjectPage />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="/help" element={<HelpAndSupport />} />
                <Route path="/safety" element={<TrustAndSafety />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<TermsOfService />} />
                <Route path="/cookies" element={<Cookies />} />
                <Route
                  path="/projects"
                  element={
                    <PrivateRoute>
                      <MyProjects />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/proposals"
                  element={
                    <PrivateRoute>
                      <MyProposals />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/proposal/:projectId"
                  element={
                    <PrivateRoute>
                      <Proposal />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/proposal/management/:projectId"
                  element={
                    <PrivateRoute>
                      <ProposalManagement />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/chat"
                  element={
                    <PrivateRoute>
                      <Chat />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/settings"
                  element={
                    <PrivateRoute>
                      <Settings />
                    </PrivateRoute>
                  }
                >
                  <Route index element={<GeneralSettings />} />
                  <Route path="general" element={<GeneralSettings />} />
                  <Route path="payments" element={<PaymentSettings />} />
                </Route>
                <Route
                  path="/favorites"
                  element={
                    <PrivateRoute>
                      <Favorites />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/project/management/:projectId"
                  element={
                    <PrivateRoute>
                      <ProjectManagement />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/project/management/:projectId/callsheet"
                  element={
                    <PrivateRoute>
                      <ProjectManagement defaultTab="callsheet" />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/submissions/role/:roleId"
                  element={
                    <PrivateRoute>
                      <ViewSubmissions />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile/:userId"
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/createproject"
                  element={
                    <PrivateRoute>
                      <CreateProject />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/editproject/:projectId"
                  element={
                    <PrivateRoute>
                      <EditProject />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </div>
            {isLoginPage ? null : <Footer />}
          </div>
        </UserProvider>
      </APIProvider>
    </ThemeProvider>
  );
};

export default App;
