import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactPlayer from "react-player";
import { supabase } from "./SupabaseClient";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

function stringAvatar(name) {
  const parts = name.split(" ");
  const firstInitial = parts[0][0];
  const secondInitial = parts.length > 1 ? parts[1][0] : ""; // Check if there is a second part, use it if so, or default to an empty string

  return {
    sx: {
      bgcolor: "#fff",
      color: "#000",
    },
    children: `${firstInitial} ${secondInitial}`.trim(), // Trim to remove any trailing space if there's no second initial
  };
}

const MyProjects = () => {
  const navigate = useNavigate();
  const colors = ["#333333", "#F28EAC", "#79B4FA", "#9F7FE3"];
  const [myProjects, setMyProjects] = useState([]);
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [inProgress, setInProgress] = useState(true);
  const [open, setOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectToDelete, setProjectToDelete] = useState("");
  const [activeCategory, setActiveCategory] = useState("in progress");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [pendingProjects, setPendingProjects] = useState([]);
  const [completedProjects, setCompletedProjects] = useState([]);
  const [activeProjects, setActiveProjects] = useState([]);

  let lastColorIndex = -1;

  // Function to get the next color index
  const getNextColorIndex = () => {
    let nextIndex;
    do {
      nextIndex = Math.floor(Math.random() * colors.length);
    } while (nextIndex === lastColorIndex);
    lastColorIndex = nextIndex;
    return nextIndex;
  };

  const calculateDuration = (duration, durationType) => {
    if (!duration || !durationType) {
      return "";
    }

    if (durationType === "Hours") {
      if (duration === "1") {
        return `${duration} Hour`;
      } else {
        return `${duration} Hours`;
      }
    } else if (durationType === "Days") {
      if (duration === "1") {
        return `${duration} Day`;
      } else {
        return `${duration} Days`;
      }
    } else if (durationType === "Weeks") {
      if (duration === "1") {
        return `${duration} Week`;
      } else {
        return `${duration} Weeks`;
      }
    } else if (durationType === "Months") {
      if (duration === "1") {
        return `${duration} Month`;
      } else {
        return `${duration} Months`;
      }
    }
  };

  const navigateToProjectPage = (id) => {
    navigate(`/project/${id}`);
  };

  const navigateToEditProjectPage = (id) => {
    navigate(`/editproject/${id}`);
  };

  const handleClose = () => {
    setOpen(false);
    setProjectName("");
    setProjectToDelete("");
  };

  const handleOpen = (projectName, projectId) => {
    setOpen(true);
    setProjectName(projectName);
    setProjectToDelete(projectId);
  };

  const navigateToProjectManagementPage = (id) => {
    navigate("/project/management/" + id);
  };

  const deleteProject = async () => {
    try {
      const response = await fetch(
        `https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/projects?id=${projectToDelete}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Remove the deleted project from the projects array
      setMyProjects((projects) =>
        projects.filter((project) => project.id !== projectToDelete)
      );

      handleClose();
    } catch (error) {
      console.error("Delete project failed:", error);
    }
  };

  const getMyProjects = async (filter) => {
    setInProgress(true);
    let query = supabase
      .from("Projects")
      .select(
        `
  *,
  Users (first_name, last_name, profile_picture),
  ProjectRoles (*)
`
      )
      .eq("owner", currentUser.sub);

    if (filter) {
      query = query.eq("project_type", filter);
    }

    const { data, error } = await query;
    if (data) {
      setInProgress(false);
      setMyProjects(data);
    }
  };

  const getProjectsByFilter = async (filter) => {
    setMyProjects([]);
    setActiveCategory(filter);
    if (filter === "proposals") {
      setMyProjects(pendingProjects);
    } else if (filter === "in progress") {
      setMyProjects(activeProjects);
    } else if (filter === "completed") {
      setMyProjects(completedProjects);
    } else if (filter === "favorites") {
      getMyFavorites();
    }
  };

  const getProjectsWithProposals = async () => {
    if (currentUser.sub === "1910a5d2-ab7d-4fed-b85e-025cbb5f526c") {
      // Admin query - get all projects
      const { data, error } = await supabase
        .from("Projects")
        .select(
          `
        *,
        Users (first_name, last_name, profile_picture),
        ProjectRoles (*)
      `
        )
        .eq("type", "paid")
        .eq("proposal_stage", "pending")
        .order("created_at", { ascending: false });

      if (data) {
        setInProgress(false);
        setPendingProjects(data);
      }
    } else {
      // Regular user query - get owned projects and projects where user has a role
      const ownedProjects = await supabase
        .from("Projects")
        .select(
          `
        *,
        Users (first_name, last_name, profile_picture),
        ProjectRoles (*)
      `
        )
        .eq("type", "paid")
        .eq("proposal_stage", "pending")
        .eq("owner", currentUser.sub)
        .order("created_at", { ascending: false });

      const projectRoles = await supabase
        .from("ProjectRoles")
        .select(
          `
        project_id,
        Projects (
          *,
          Users (first_name, last_name, profile_picture),
          ProjectRoles (*)
        )
      `
        )
        .eq("user", currentUser.sub);

      const projectsWithRoles =
        projectRoles.data
          ?.map((role) => role.Projects)
          .filter(
            (project) =>
              project.type === "paid" && project.proposal_stage === "pending"
          ) || [];

      const combinedProjects = [
        ...(ownedProjects.data || []),
        ...projectsWithRoles,
      ];

      // Remove duplicates based on project id
      const uniqueProjects = Array.from(
        new Map(combinedProjects.map((item) => [item.id, item])).values()
      );

      setInProgress(false);
      setPendingProjects(uniqueProjects);
    }
  };

  const getProjectsInProgress = async () => {
    if (currentUser.sub === "1910a5d2-ab7d-4fed-b85e-025cbb5f526c") {
      // Admin query - get all projects
      const { data, error } = await supabase
        .from("Projects")
        .select(
          `
        *,
        Users (first_name, last_name, profile_picture),
        ProjectRoles (*)
      `
        )
        .eq("status", "pending")
        .eq("proposal_stage", "complete")
        .order("created_at", { ascending: false });

      if (data) {
        setInProgress(false);
        setActiveProjects(data);
      }
    } else {
      // Regular user query - get owned projects and projects where user has a role
      const ownedProjects = await supabase
        .from("Projects")
        .select(
          `
        *,
        Users (first_name, last_name, profile_picture),
        ProjectRoles (*)
      `
        )
        .eq("status", "pending")
        .eq("proposal_stage", "complete")
        .eq("owner", currentUser.sub)
        .order("created_at", { ascending: false });

      const projectRoles = await supabase
        .from("ProjectRoles")
        .select(
          `
        project_id,
        Projects (
          *,
          Users (first_name, last_name, profile_picture),
          ProjectRoles (*)
        )
      `
        )
        .eq("user", currentUser.sub);

      const projectsWithRoles =
        projectRoles.data
          ?.map((role) => role.Projects)
          .filter(
            (project) =>
              project.status === "pending" &&
              project.proposal_stage === "complete"
          ) || [];

      const combinedProjects = [
        ...(ownedProjects.data || []),
        ...projectsWithRoles,
      ];

      // Remove duplicates based on project id
      const uniqueProjects = Array.from(
        new Map(combinedProjects.map((item) => [item.id, item])).values()
      );

      setInProgress(false);
      setActiveProjects(uniqueProjects);
    }
  };

  const getProjectsCompleted = async () => {
    if (currentUser.sub === "1910a5d2-ab7d-4fed-b85e-025cbb5f526c") {
      // Admin query - get all projects
      const { data, error } = await supabase
        .from("Projects")
        .select(
          `
        *,
        Users (first_name, last_name, profile_picture),
        ProjectRoles (*)
      `
        )
        .eq("status", "completed")
        .order("created_at", { ascending: false });

      if (data) {
        setInProgress(false);
        setCompletedProjects(data);
      }
    } else {
      // Regular user query - get owned projects and projects where user has a role
      const ownedProjects = await supabase
        .from("Projects")
        .select(
          `
        *,
        Users (first_name, last_name, profile_picture),
        ProjectRoles (*)
      `
        )
        .eq("status", "completed")
        .eq("owner", currentUser.sub)
        .order("created_at", { ascending: false });

      const projectRoles = await supabase
        .from("ProjectRoles")
        .select(
          `
        project_id,
        Projects (
          *,
          Users (first_name, last_name, profile_picture),
          ProjectRoles (*)
        )
      `
        )
        .eq("user", currentUser.sub);

      const projectsWithRoles =
        projectRoles.data
          ?.map((role) => role.Projects)
          .filter((project) => project.status === "completed") || [];

      const combinedProjects = [
        ...(ownedProjects.data || []),
        ...projectsWithRoles,
      ];

      // Remove duplicates based on project id
      const uniqueProjects = Array.from(
        new Map(combinedProjects.map((item) => [item.id, item])).values()
      );

      setInProgress(false);
      setCompletedProjects(uniqueProjects);
    }
  };

  const getMyFavorites = async () => {
    setInProgress(true);
    const { data, error } = await supabase
      .from("Favorites")
      .select(
        `
  *,
  Users (first_name, last_name),
  Projects (*)
`
      )
      .match(
        currentUser.sub !== "1910a5d2-ab7d-4fed-b85e-025cbb5f526c"
          ? { user: currentUser.sub }
          : {}
      );
    if (data) {
      setInProgress(false);
      console.log(data);
      if (data.Projects && data.Projects.length > 0) {
        setMyProjects(data.Projects);
      } else {
        setMyProjects([]);
      }
    }
  };

  const navigateToProposalManagementPage = (id) => {
    navigate(`/proposal/management/${id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getProjectsInProgress();
    getProjectsCompleted();
    getProjectsWithProposals();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        className="custom-margins"
        sx={{
          paddingBottom: "3rem",
          paddingTop: "2rem",
        }}
      >
        {" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: "30px",
            alignItems: {
              xs: "center",
              sm: "flex-start",
            },
          }}
        >
          <Typography
            sx={{
              color: "#222222",
              textAlign: "left",
              marginBottom: "30px",
              fontSize: "30px",
              fontWeight: "600",
              animation: "fadeInUp 0.8s ease-out",
              "@keyframes fadeInUp": {
                "0%": {
                  opacity: 0,
                  transform: "translateY(20px)",
                },
                "100%": {
                  opacity: 1,
                  transform: "translateY(0)",
                },
              },
            }}
          >
            {t("welcome")}, {currentUser.first_name}
          </Typography>
          <Typography
            sx={{
              color: "#222222",
              textAlign: "left",
              marginBottom: "30px",
              fontSize: "16px",
              fontWeight: "400",
              animation: "fadeInUp 0.8s ease-out",
              "@keyframes fadeInUp": {
                "0%": {
                  opacity: 0,
                  transform: "translateY(20px)",
                },
                "100%": {
                  opacity: 1,
                  transform: "translateY(0)",
                },
              },
            }}
          >
            {t("myProjects")}
          </Typography>
          {/* <Box
            sx={{
              display: "flex",
              gap: 1,
              marginBottom: "40px",
              width: "auto",
              backgroundColor: "#f2f2f2",
              padding: "8px",
              borderRadius: "20px",
            }}
          >
            <Typography
              onClick={() => {
                setActiveCategory("proposals");
                getProjectsByFilter("proposals");
              }}
              sx={{
                cursor: "pointer",
                backgroundColor:
                  activeCategory === "proposals" ? "#ffffff" : "#f2f2f2",
                padding: "6px 12px 6px 12px",
                borderRadius: "20px",
                color: activeCategory === "proposals" ? "#000000" : "#646464",
                fontSize: "12px",
                fontWeight: activeCategory === "proposals" ? "400" : "300",
                transition: "all 0.5s ease",
                "&:hover": {
                  backgroundColor:
                    activeCategory === "proposals" ? "#ffffff" : "#e8e8e8",
                },
              }}
            >
              {t("acceptingProposals")}
            </Typography>
            <Typography
              onClick={() => {
                setActiveCategory("in progress");
                getProjectsByFilter("in progress");
              }}
              sx={{
                cursor: "pointer",
                padding: "6px 12px 6px 12px",
                backgroundColor:
                  activeCategory === "in progress" ? "#ffffff" : "#f2f2f2",
                borderRadius: "20px",
                color: activeCategory === "in progress" ? "#000000" : "#646464",
                fontSize: "12px",
                fontWeight: activeCategory === "in progress" ? "400" : "300",
                transition: "all 0.5s ease",
                "&:hover": {
                  backgroundColor:
                    activeCategory === "in progress" ? "#ffffff" : "#e8e8e8",
                },
              }}
            >
              {t("inProgress")}
            </Typography>
            <Typography
              onClick={() => {
                setActiveCategory("completed");
                getProjectsByFilter("completed");
              }}
              sx={{
                cursor: "pointer",
                padding: "6px 12px 6px 12px",
                backgroundColor:
                  activeCategory === "completed" ? "#ffffff" : "#f2f2f2",
                borderRadius: "20px",
                color: activeCategory === "completed" ? "#000000" : "#646464",
                fontSize: "12px",
                fontWeight: activeCategory === "completed" ? "400" : "300",
                transition: "all 0.5s ease",
                "&:hover": {
                  backgroundColor:
                    activeCategory === "completed" ? "#ffffff" : "#e8e8e8",
                },
              }}
            >
              {t("completed")}
            </Typography>
            <Typography
              onClick={() => {
                setActiveCategory("favorites");
                getProjectsByFilter("favorites");
              }}
              sx={{
                cursor: "pointer",
                padding: "6px 12px 6px 12px",
                backgroundColor:
                  activeCategory === "favorites" ? "#ffffff" : "#f2f2f2",
                borderRadius: "20px",
                color: activeCategory === "favorites" ? "#000000" : "#646464",
                fontSize: "12px",
                fontWeight: activeCategory === "favorites" ? "400" : "300",
                transition: "all 0.5s ease",
                "&:hover": {
                  backgroundColor:
                    activeCategory === "favorites" ? "#ffffff" : "#e8e8e8",
                },
              }}
            >
              {t("favorites")}
            </Typography>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
              },
              gap: "30px",
              justifyContent: "center",
              width: "100%",
              flexWrap: "wrap",
              alignItems: {
                xs: "center",
                sm: "flex-start",
              },
            }}
          >
            <Card
              sx={{
                width: "210px", // Reduced from 300px
                height: "202px", // Reduced from 288px
                display: "flex",
                boxShadow: "none",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ffffff",
                borderRadius: "12px",
                border: "1px solid rgba(0,0,0,0.08)",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                  borderColor: "rgba(0,0,0,0.12)",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "34px", // Reduced from 48px
                  fontWeight: "600",
                  color: "#333",
                  marginBottom: "6px", // Reduced from 8px
                }}
              >
                {activeProjects.length}
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px", // Reduced from 16px
                  color: "#666",
                  fontWeight: "300",
                }}
              >
                Active
              </Typography>
              <Box
                sx={{
                  width: "56px", // Reduced from 80px
                  height: "3px", // Reduced from 4px
                  backgroundColor: "#9f7fe3",
                  marginTop: "11px", // Reduced from 16px
                  marginBottom: "11px", // Reduced from 16px
                  borderRadius: "2px",
                  animation: "expandFromCenter 0.8s ease-out",
                  "@keyframes expandFromCenter": {
                    "0%": {
                      width: "0px",
                      marginLeft: "28px", // Reduced from 40px
                    },
                    "100%": {
                      width: "56px", // Reduced from 80px
                      marginLeft: "0px",
                    },
                  },
                }}
              />
              <Button
                onClick={() => {
                  setActiveCategory("in progress");
                  getProjectsByFilter("in progress");
                }}
                variant="text"
                sx={{
                  fontSize: "8px", // Reduced from 12px
                  color: "#666",
                  position: "relative",
                  width: "auto",
                  padding: "4px 2px 4px 2px",
                  border: "none",
                  right: "-4.2rem", // Reduced from -6rem
                  bottom: "-1.4rem", // Reduced from -2rem
                  marginTop: "11px", // Reduced from 16px
                  backgroundColor: "#f8f8f8",
                  "&:hover": {
                    backgroundColor: "rgba(159, 127, 227, 0.04)",
                  },
                }}
              >
                View
              </Button>
            </Card>
            <Card
              sx={{
                width: "210px", // Reduced from 300px
                height: "202px", // Reduced from 288px
                display: "flex",
                flexDirection: "column",
                boxShadow: "none",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ffffff",
                borderRadius: "12px",
                border: "1px solid rgba(0,0,0,0.08)",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                  borderColor: "rgba(0,0,0,0.12)",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "34px", // Reduced from 48px
                  fontWeight: "600",
                  color: "#333",
                  marginBottom: "6px", // Reduced from 8px
                }}
              >
                {pendingProjects.length}
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px", // Reduced from 16px
                  color: "#666",
                  fontWeight: "300",
                }}
              >
                Pending
              </Typography>
              <Box
                sx={{
                  width: "56px", // Reduced from 80px
                  height: "3px", // Reduced from 4px
                  backgroundColor: "red",
                  marginTop: "11px", // Reduced from 16px
                  marginBottom: "11px", // Reduced from 16px
                  borderRadius: "2px",
                  animation: "expandFromCenter 0.8s ease-out",
                  "@keyframes expandFromCenter": {
                    "0%": {
                      width: "0px",
                      marginLeft: "28px", // Reduced from 40px
                    },
                    "100%": {
                      width: "56px", // Reduced from 80px
                      marginLeft: "0px",
                    },
                  },
                }}
              />
              <Button
                variant="text"
                onClick={() => {
                  setActiveCategory("proposals");
                  getProjectsByFilter("proposals");
                }}
                sx={{
                  fontSize: "8px", // Reduced from 12px
                  color: "#666",
                  position: "relative",
                  padding: "4px 2px 4px 2px",
                  border: "none",
                  right: "-4.2rem", // Reduced from -6rem
                  bottom: "-1.4rem", // Reduced from -2rem
                  marginTop: "11px", // Reduced from 16px
                  backgroundColor: "#f8f8f8",
                  "&:hover": {
                    backgroundColor: "rgba(159, 127, 227, 0.04)",
                  },
                }}
              >
                View
              </Button>
            </Card>
            <Card
              sx={{
                width: "210px", // Reduced from 300px
                height: "202px", // Reduced from 288px
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ffffff",
                borderRadius: "12px",
                border: "1px solid rgba(0,0,0,0.08)",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                  borderColor: "rgba(0,0,0,0.12)",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "34px", // Reduced from 48px
                  fontWeight: "600",
                  color: "#333",
                  marginBottom: "6px", // Reduced from 8px
                }}
              >
                {completedProjects.length}
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px", // Reduced from 16px
                  color: "#666",
                  fontWeight: "300",
                }}
              >
                Completed
              </Typography>
              <Box
                sx={{
                  width: "56px", // Reduced from 80px
                  height: "3px", // Reduced from 4px
                  backgroundColor: "#4CAF50",
                  marginTop: "11px", // Reduced from 16px
                  marginBottom: "11px", // Reduced from 16px
                  borderRadius: "2px",
                  animation: "expandFromCenter 0.8s ease-out",
                  "@keyframes expandFromCenter": {
                    "0%": {
                      width: "0px",
                      marginLeft: "28px", // Reduced from 40px
                    },
                    "100%": {
                      width: "56px", // Reduced from 80px
                      marginLeft: "0px",
                    },
                  },
                }}
              />
              <Button
                variant="text"
                onClick={() => {
                  setActiveCategory("completed");
                  getProjectsByFilter("completed");
                }}
                sx={{
                  fontSize: "8px", // Reduced from 12px
                  color: "#666",
                  position: "relative",
                  padding: "4px 2px 4px 2px",
                  border: "none",
                  right: "-4.2rem", // Reduced from -6rem
                  bottom: "-1.4rem", // Reduced from -2rem
                  marginTop: "11px", // Reduced from 16px
                  backgroundColor: "#f8f8f8",
                  "&:hover": {
                    backgroundColor: "rgba(159, 127, 227, 0.04)",
                  },
                }}
              >
                View
              </Button>
            </Card>

            {/* {!inProgress && myProjects.length === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "3rem",
                }}
              >
                <div style={{ maxWidth: "400px", width: "100%" }}>
                  <DotLottieReact
                    src={
                      "https://noozjeclpgnzvbgdtser.supabase.co/storage/v1/object/public/media/no_results.lottie"
                    }
                    loop
                    autoplay
                  />
                </div>

                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#c7c7c7",
                  }}
                >
                  {t("noProjects")}
                </Typography>
              </div>
            )}
            {inProgress &&
              Array.from({ length: 4 }).map((_, index) => (
                // Your JSX here. Use `index` if you need the iteration count.
                <Card key={index} className="card-project">
                  <CardMedia
                    sx={{ width: "300px", height: "288px" }}
                    title="photoshoot"
                  >
                    {" "}
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="100%"
                    ></Skeleton>
                  </CardMedia>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "10px 8px 0px 8px",
                      height: "110px",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Skeleton variant="text" width="80%"></Skeleton>
                    <Skeleton variant="text" width="80%"></Skeleton>
                  </Box>
                </Card>
              ))} */}
          </Box>
          <Box
            sx={{
              marginTop: "3rem",
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
              },
              gap: "30px",
              width: "100%",
              flexWrap: "wrap",
              alignItems: {
                xs: "center",
                sm: "flex-start",
              },
            }}
          >
            {myProjects &&
              myProjects.length > 0 &&
              myProjects.map((project) => {
                return (
                  <Card key={project.id} className="card-project">
                    {project.media.length > 0 &&
                      ReactPlayer.canPlay(project.media[0]) && (
                        <CardMedia
                          onClick={() => navigateToProjectPage(project.id)}
                          className="card-media"
                        >
                          <div
                            style={{ position: "relative", height: "234px" }}
                          >
                            <ReactPlayer
                              url={project.media[0]}
                              width="300px"
                              height="288px"
                              playing={true}
                              loop={true}
                              volume={1}
                              muted={true}
                              controls={false}
                              className="react-player"
                            />
                            <div
                              className="gradient-overlay"
                              style={{
                                position: "absolute",
                                height: "288px",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background:
                                  "linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%)",
                              }}
                            />

                            <div
                              style={{
                                position: "relative",
                                bottom: "288px",
                              }}
                            >
                              <div className="title">{project.name}</div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginLeft: "18px",
                              marginBottom: "10px",
                              zIndex: "1",
                              paddingRight: "1rem",
                            }}
                          >
                            <Avatar
                              className="avatar"
                              {...stringAvatar(
                                `${project.Users.first_name} ${project.Users.last_name}`
                              )}
                              src={project.Users.profile_picture}
                            />
                            <div className="owner">
                              {`${project.Users.first_name} ${project.Users.last_name}`}
                            </div>
                          </div>
                        </CardMedia>
                      )}
                    {project.media.length > 0 &&
                      !ReactPlayer.canPlay(project.media[0]) && (
                        <CardMedia
                          onClick={() => navigateToProjectPage(project.id)}
                          className="card-media"
                          sx={{
                            background: `linear-gradient(
                          0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%
                        ), url(${project.media[0]}) lightgray 50% / cover no-repeat`,
                          }}
                          title="photoshoot"
                        >
                          <div>
                            <div className="title">{project.name} </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginBottom: "10px",
                              paddingLeft: "1rem",
                              paddingRight: "1rem",
                            }}
                          >
                            <Avatar
                              className="avatar"
                              {...stringAvatar(
                                `${project.Users.first_name} ${project.Users.last_name}`
                              )}
                              src={project.Users.profile_picture}
                            />
                            <div className="owner">
                              {`${project.Users.first_name} ${project.Users.last_name}`}
                            </div>
                          </div>
                        </CardMedia>
                      )}

                    {!project.media.length && (
                      <CardMedia
                        onClick={() => navigateToProjectPage(project.id)}
                        className="card-media"
                        sx={{
                          background: `${colors[getNextColorIndex()]}`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                        title="photoshoot"
                      >
                        <div>
                          <div className="title">{project.name} </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginBottom: "10px",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <Avatar
                            className="avatar"
                            {...stringAvatar(
                              `${project.Users.first_name} ${project.Users.last_name}`
                            )}
                          />
                          <div className="owner">{`${project.Users.first_name} ${project.Users.last_name}`}</div>
                        </div>
                      </CardMedia>
                    )}

                    <CardContent
                      sx={{ padding: "12px 12px 12px 12px", height: "45px" }}
                    >
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: project.description,
                        }}
                      ></div>
                    </CardContent>
                    {project.status === "pending" &&
                      project.proposal_stage === "pending" && (
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Button
                            size="small"
                            variant="outlined"
                            className="project-card-btn"
                            onClick={() =>
                              currentUser.sub ===
                              "1910a5d2-ab7d-4fed-b85e-025cbb5f526c"
                                ? navigateToProposalManagementPage(project.id)
                                : navigateToProjectManagementPage(project.id)
                            }
                          >
                            {t("manage")}
                          </Button>
                          {/* <IconButton aria-label="delete">
                        <DeleteIcon />
                      </IconButton> */}
                        </CardActions>
                      )}
                    {project.status === "pending" &&
                      project.proposal_stage === "complete" && (
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Chip
                            className="chip"
                            icon={
                              <GroupsIcon
                                sx={{ color: "#929292", width: "19px" }}
                              />
                            }
                            label={
                              project.ProjectRoles &&
                              `${project.ProjectRoles.length} people`
                            }
                          />
                          {project.duration && project.duration_type && (
                            <Chip
                              className="chip"
                              icon={
                                <TimelapseIcon
                                  sx={{ color: "#929292", width: "19px" }}
                                />
                              }
                              label={calculateDuration(
                                project.duration,
                                project.duration_type
                              )}
                            />
                          )}

                          <Button
                            size="small"
                            variant="outlined"
                            className="project-card-btn"
                            onClick={() =>
                              navigateToProjectManagementPage(project.id)
                            }
                          >
                            {t("manage")}
                          </Button>
                          {/* <IconButton aria-label="delete">
                        <DeleteIcon />
                      </IconButton> */}
                        </CardActions>
                      )}
                  </Card>
                );
              })}
            <Dialog
              onClose={handleClose}
              open={open}
              PaperProps={{
                style: { width: "50%" }, // Custom width
              }}
            >
              <DialogTitle>Remove Project</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please confirm if you want to remove {projectName} from your
                  projects
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={deleteProject}>Confirm</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyProjects;
