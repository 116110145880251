// theme.js or theme.ts
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    body1: {
      fontSize: "12px", // Default font size for body text
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "12px", // Default font size for buttons
          backgroundColor: "#9f7fe3",
          color: "#ffffff",
          ":hover": {
            backgroundColor: "#9f7fe3d8",
          },
          textTransform: "none",
          fontWeight: "300",
          // You can add more styles here if needed
        },
        sizeSmall: {
          fontSize: "12px", // Font size for small buttons
        },
        sizeLarge: {
          fontSize: "16px", // Font size for large buttons
        },
      },
    },
  },
});

export default theme;
